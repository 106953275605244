/* product list css */

.rcs_shape_wizard_content ul li.rcs_setting_details h2 {
  color: #000;
}   
.rcs_shape_wizard_content ul li.rcs_setting_details.active h2,.rcs_shape_wizard_content ul li.rcs_setting_details.active a,.rcs_shape_wizard_content ul li.rcs_setting_price.active p {
  color: var(--inner-primary) !important;
}       
.rcs_shape_wizard_content ul li.rcs_setting_details a, .rcs_shape_wizard_content ul li.rcs_setting_details a:hover {
  color: #000;
}
.rc-slider-track {
  background-color: #000 !important;
}
.rc-slider-handle {
  border: solid 1px #fff !important;
  background: var(--inner-primary) !important;
}
.rc-slider-handle:active {
  border-color: #000 !important;
  box-shadow: 0 0 5px #000 !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #000 !important;
  box-shadow: 0 0 0 5px #efefef !important;
}    
.rc-slider-dot-active {
  border-color: #000 !important;
}
.rc-slider-dot {
  border: 0px solid #e9e9e9 !important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover {
  border: 2px solid #000 !important;
}
.image-gallery-thumbnail {
  border: 2px solid transparent !important;
}



.rcs_accordion {
  margin-bottom: 50px;
  /* margin-right: 35px; */
}

.\/jewelry\/fine-jewelry .rcs_accordion,
.\/jewelry\/wedding-rings .rcs_accordion {
  margin-bottom: 50px;
  /* margin-right: 35px; */
}

.\/jewelry\/fine-jewelry .rcs_sorting_title,
.\/jewelry\/wedding-rings .rcs_sorting_title {
  cursor: pointer;
  margin-bottom: 30px;
}

.rcs_product_grid .rcs_product_item a {
  /* height: 308px !important; */
}

.rcs_prodlist_banner img {
  width: 100%;
}

.rcs_accordion_sec .rcs_accordion_item::before {
  opacity: 0 !important;
}

.rcs_category_title h1 {
  font-size: 36px;
  line-height: 1.00;
  margin-bottom: 13px;
  margin-top: 20px;
  text-transform: capitalize;
}

li.chakra-breadcrumb__list-item.css-18biwo:last-child {
  font-weight: 300;
}

.rcs_category_title p {
  margin-bottom: 25px;
}

.rcs_view {
  /* text-align: center;
  padding: 13px 0px; */
}

.rcs_view span {
  /* font-size: 16px;
  padding-right: 10px;
  font-family: var(--font_1); */
}

.rcs_view .active_grid {
  cursor: pointer;
}

.rcs_sort_filter {
  text-align: center;
  /* padding: 10px 0px; */
}

.rcs_sort_filter span {
  font-size: 12px;
  font-weight: 600;
  padding-right: 8px;
}

.rcs_sorting .prev {
  width: 20%;
  cursor: pointer;
}

.rcs_sorting .next {
  width: 20%;
}

.rcs_accordion .rcs_accordion_item .MuiTypography-root {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  font-family: var(--font_1);
}

.rcs_product_item a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center
}

.color-overlay {
  opacity: .03;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.rcs_prod_info {
  padding: 15px;
}

.js_space {
  justify-content: space-between;
}

.rcs_prod_info p {
  font-family: var(--inner_font);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--inner-primary);
  margin: 0;
}

.rcs_product_item img {
  width: 100%;
  object-fit: contain;
}

.watchList.rcs_product_item img,
 .\/jewelry\/fine-jewelry.rcs_product_item img,
 .\/jewelry\/fashion-rings.rcs_product_item img,
 .\/jewelry\/pendants.rcs_product_item img,
 .\/jewelry\/necklaces.rcs_product_item img,
 .\/jewelry\/earrings.rcs_product_item img,
 .\/jewelry\/bracelets.rcs_product_item img {
  width: 100%;
  height: 335px;
  object-fit: contain;
}

.rcs_prod_info h2 {
  padding: 0;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--inner_font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

}

.rcs_list_filter {
  padding: 0px;
}

.rcs_product_grid {
  margin-top: 0px;
  margin-bottom: 50px;
}

.rcs_product_item {
  margin-bottom: 20px;
  /* 
  position: relative;
  padding: 15px;
  min-height: 270px;
  border: 1px solid #1313130f; */
}

.pro_imgg_outer_boxx {
  min-height: 150px;
}

.pro_imgg_outer_boxx img {
  width: 100%;
  /* height: auto; */
  object-fit: contain;
  /* margin-bottom: 30px; */
}

.rcs_product_item:hover {
  box-shadow: 0px 4px 20px 0px #0000001A;
}

button.rcs_wish_btn svg {
  height: 25px;
  width: 26px;
}

button.rcs_wish_btn {
  border: 0px;
  background: none;
}

.rcs_pagination ul {
  align-items: center;
  justify-content: center;
}

.slick-slider.rcs_list_slider.slick-initialized {
  max-width: 285px;
  border: 0px;
}

.slick-slider.rcs_list_slider {
  width: 285px;
  border: 0px;
}

.rcs_list_slider button.slick-arrow.slick-next {
  background: #ededed;
  width: 37px;
  height: 37px;
  position: absolute;
  right: 7px;
  z-index: 999;
}

.rcs_list_slider button.slick-arrow.slick-prev {
  background: #ededed;
  width: 37px;
  height: 37px;
  position: absolute;
  left: 7px;
  z-index: 999;
}

.rcs_accordion .MuiButtonBase-root {
  padding: 0px 10px;
  display: flex !important;
  min-height: -webkit-fill-available !important;
}

.rcs_accordion p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  font-weight: 700;
  font-size: 17px;
  font-family: var(--font_1);
}

.rcs_accordion .css-15v22id-MuiAccordionDetails-root {
  padding: 0px 0px;
}

.rcs_accordion .css-o4b71y-MuiAccordionSummary-content.Mui-expanded,
.rcs_accordion .css-o4b71y-MuiAccordionSummary-content {
  margin: 10px -10px;
}

.rcs_accordion .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.rcs_accordion_item.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  margin: 0 !important;
}

.rcs_price_filter .css-j204z7-MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.rcs_product_list_wrapper {
  /* padding: 0px 15px; */
}

.rcs_view svg:hover {
  cursor: pointer;
}

.rcs_view svg {
  cursor: pointer;
}

.slick-slider.rcs_list_slider .slick-next {
  content: "";
  background: url('https://dl2vs6wk2ewna.cloudfront.net/showcase/images/right_icon.png') !important;
  width: 54px;
  height: 46px;
  z-index: 99999999;
  background-size: 50% !important;
  position: absolute;
  right: 5px;
  background-repeat: no-repeat !important;
  background-color: #ededed !important;
  background-position: center !important;
}

.slick-slider.rcs_list_slider .slick-prev {
  content: "";
  background: url('https://dl2vs6wk2ewna.cloudfront.net/showcase/images/left_icon.png') !important;
  width: 54px;
  height: 46px;
  z-index: 99999999;
  background-size: 50% !important;
  position: absolute;
  background-repeat: no-repeat !important;
  background-color: #ededed !important;
  background-position: center !important;
  left: 0px;
}

.rcs_list_slider .slick-next:before {
  content: none !important;
}

.rcs_list_slider .slick-prev:before {
  content: none !important;
}

.rcs_accordion_sec .MuiAccordionSummary-expandIconWrapper:after {
  content: "+" !important;
  font-size: 26px;
  font-weight: 600;
}

.rcs_accordion_sec .MuiAccordionSummary-expandIconWrapper.Mui-expanded:after {
  content: "-" !important;
  font-size: 26px;
  font-weight: 600;
}

.rcs_accordion_sec .MuiIconButton-root .MuiIconButton-label svg {
  display: none;
}

.rcs_accordion_sec .MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
  display: none;
}

.gs_accordion_sec .MuiAccordionSummary-expandIconWrapper .Mui-expanded:before {
  content: "-" !important;
  font-size: 26px;
  font-weight: 600;
}

.rcs_wish_btn i.far.fa-heart {
  font-size: 22px;
}

.rcs_product_item_list {
  position: relative;
  max-width: 250px;
  margin: 0 auto;
}

.rcs_product_item_list img {
  width: 100%;
}

.rcs_product_item_list .rcs_wish_btn {
  position: absolute;
  right: 7px;
  top: 5px;
}

.rcs_list_title {
  font-size: 16px;
  letter-spacing: .125rem;
  line-height: 1.00;
  font-weight: bold;
}

.rcs_list_email {
  font-size: 14px;
  letter-spacing: .125rem;
  margin-bottom: 0px;
}

.rcs_dblock button {
  font-family: var(--font_1);
  font-size: 12px;
}

.rcs_dblock button svg {
  margin-right: 5px;
}

h5.rcs_bottom_title {
  font-size: 20px;
  letter-spacing: .125rem;
}

.rcs_bottom_text p {
  font-size: 17px;
}

.rcs_bottom_text {
  padding: 50px 0;
}

.rcs_accordion_sec {
  position: sticky;
  top: 70px;
}

.rcs_accordion_sec .rcs_accordion_item {
  /* position: sticky; */
  /* top: 128px; */
  background: #f5f8f9;
  box-shadow: none !important;
  margin: 0 !important;
}

.rcs_filter_list h5 {
  position: relative;
  margin: 0;
  padding: .75rem 0;
  line-height: 14px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.rcs_filter_list h5 .clear-all-filters {
  width: auto;
  display: inline-block;
  float: none;
  white-space: nowrap;
  font-size: .7rem;
  letter-spacing: .03rem;
  font-weight: normal;
  text-decoration: underline;
  background: transparent;
  border: none;
}

.rcs_filt_tags {
  margin-top: 0;
  margin-bottom: .25rem;
  padding: 4px 6px;
  font-size: 12px;
  letter-spacing: normal;
  font-weight: normal;
  border-radius: 0px;
  width: 80%;
}

.filter_tgs_list .gs_filter_btn {
  width: 100%;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 2px 7px;
  border-radius: 0px;
  margin-bottom: 5px;
  font-family: var(--font_1);
  font-weight: 600;
}

.rcs_prodlist_item .rcs_list_img {
  /* border: 1px solid #d2d2d2; */
  max-width: 230px;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
}

.rcs_prodlist_item .slick-slider.rcs_list_slider.slick-initialized {
  max-width: 225px !important;
  border: 0px;
}

.rcs_prod_list_info {
  /* margin-left: -22px; */
}

.rcs_dblock button {
  font-weight: 600;
}

.rcs_prod_list_info .rcs_list_title {
  margin-top: 55px;
  font-family: var(--inner_font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
}

p.rcs_main_price {
  font-family: var(--inner_font);
  font-size: 20px;
  font-weight: 500;
  color: var(--inner-primary);
  margin: 0;
}

.mg_nopad {
  padding: 0px;
}

.rcs_dblock button:hover {
  background: none;
}

.rcs_prodlist_item {
  margin-top: 10px;
  padding-top: 10px;
}

.rcs_product_item:hover .hover-buttons {
  opacity: 1;
}

.rcs_hover_buttons {
  position: absolute;
  top: 0px;
  z-index: 9999;
  width: 100%;
  opacity: 0 !important;
}

.hover-buttons {
  opacity: 0;
}

.rcs_list_img button.rcs_wish_btn {
  display: none;
}

.rcs_list_img:hover button.rcs_wish_btn {
  border: 0px;
  background: none;
  position: absolute;
  right: 5px;
  top: 5px;
  display: block;
  z-index: 99;
}

.hover-buttons button {
  padding: 0px;
  display: contents;
}

.hover-buttons span img {
  width: 18px;
  margin: 8px;
  cursor: pointer;
}

.d-flex.hover-buttons {
  /* position: relative;
  top: 34px;
  z-index: 999; */
}

.rcs_dblock button img {
  width: 16px;
  margin-right: 5px;
}

.rcs_prodlist_item:first-child {
  border-top: none !important;
}

.rcs_accordion_item .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}

.rcs_accordion_item .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}

.rcs_accordion_item .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
  margin-bottom: 15px;
}

.Mui-expanded.rcs_accordion_item.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-radius: 0;
}

.rcs_accordion_item div#panel2a-content {
  overflow-y: auto;
  height: auto;
  max-height: 300px;
}

.rcs_accordion_item .MuiCollapse-root>.MuiCollapse-vertical {
  padding: 15px 0;
  /* border-bottom: 1px solid rgba(0,0,0,.12); */
}

.rcs_mob_filter {
  display: none;
}

/* product list view css */
.rcs_sort_filter .form-select {
  padding: 5px 10px;
}

/*quickview css*/
.rcs_quick_view img {
  width: 310px;
  margin: 0 auto;
  display: block;
}

/*quickview css*/
/*server css*/
.rcs_accordion .css-67l5gl.Mui-expanded,
.rcs_accordion .css-67l5gl {
  box-shadow: none;
  margin: 0 !important;
}

.rcs_accordion .css-17o5nyn.Mui-expanded.Mui-expanded,
.rcs_accordion .css-17o5nyn.Mui-expanded {
  margin: 10px 0px;
}

.rcs_accordion p.css-9l3uo3 {
  font-weight: 600;
  font-size: 17px !important;
}

.rcs_accordion_sec .MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-1fx8m19 svg,
.rcs_accordion_sec .MuiAccordionSummary-expandIconWrapper.css-1fx8m19 svg {
  display: none;
}

.rcs_accordion .css-u7qq7e {
  padding: 0px 0px;
}

.rcs_accordion_item .css-1iji0d4.Mui-expanded {
  min-height: 44px;
}

.rcs_quick_view h2 {
  font-family: var(--font_1);
  text-transform: none !important;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
}

@media (min-width: 576px) {
  .rcs_quick_modal .modal-dialog {
    max-width: 720px;
  }
}

.rcs_quick_modal .modal-footer {
  border-top: 0 !important;
}

/*server css*/
.rcs_sorting_title {
  cursor: pointer;
  margin-bottom: 30px;
}

li.rcs_price_range_input1 {
  width: 150px !important;
}

.rcs_price_range_input1 input {
  font-size: 14px;
}

/*Responsive css*/
@media (min-width: 320px) and (max-width: 575px) {

  .rcs_prodlist_banner {
    /* height: 120px; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .rcs_product_list_wrapper {
    padding: 0;
  }

  .rcs_sorting .prev,
  .rcs_sorting .next {
    font-size: 0;
  }

  .rcs_sorting_title .prev,
  .rcs_sorting_title .next {
    font-size: 0;
  }

  /*right filter*/
  .rcs_accordion_sec {
    padding: 10px 20px;
  }

  .rcs_accordion_item div#panel2a-content {
    max-height: 100%;
  }

  .rcs_accordion_sec .MuiPaper-rounded {
    border-radius: 0px;
  }

  .rcs_accordion_sec .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rcs_accordion_item .MuiCollapse-root.MuiCollapse-entered {
    padding: 5px 0;
  }

  .rcs_category_title p {
    font-size: 13px;
  }

  /* .rcs_accordion .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.rcs_accordion_item.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      line-height: 1;
  } */
  .rcs_accordion_sec .rcs_accordion_item {
    position: unset;
  }

  /*right filter*/
  /*grid section*/
  .hover-buttons {
    display: none;
  }

  .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 100%;
    border: 0px;
  }

  .rcs_prod_info h2 {
    font-size: 12px;
    line-height: 14px;
  }

  .rcs_prod_info p {
    font-size: 13px;
  }

  .rcs_list_slider button.slick-arrow.slick-prev,
  .rcs_list_slider button.slick-arrow.slick-next {
    width: 30px;
    height: 30px;
  }

  .rcs_product_item {
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .rcs_product_item:hover {
    box-shadow: none;
  }


  .rcs_product_grid {
    /* margin-top: 30px; */
    /* margin-bottom: 30px; */
  }

  .rcs_product_grid .col-xl-4.col-lg-4.col-md-4.col-sm-12.col-12 {
    padding: 0;
  }

  /*grid section*/
  .rcs_product_grid .rcs_list_slider button.slick-arrow.slick-prev {
    left: 0;
    top: 88%;
  }

  .rcs_product_grid .rcs_list_slider button.slick-arrow.slick-next {
    right: 0;
    top: 88%;
  }

  .rcs_product_grid .rcs_prod_info {
    padding-top: 20px;
    position: relative;
  }

  .rcs_product_grid button.rcs_wish_btn {
    border: 0px;
    background: none;
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 999;
    display: none;
  }

  .rcs_product_grid button.rcs_wish_btn svg {
    height: 22px;
    width: 22px;
  }

  .rcs_pagination ul li .MuiPaginationItem-previousNext,
  .rcs_pagination ul li .MuiPaginationItem-previousNext {
    display: none;
  }
}

/*product list grid css*/
@media (min-width: 320px) and (max-width: 359px) {
  .rcs_accordion_sec {
    width: 270px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 135px !important; */
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 130px;
  }
}

@media (min-width: 360px) and (max-width: 410px) {
  .rcs_accordion_sec {
    width: 300px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 155px !important; */
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 150px;
  }

}

@media (min-width: 411px) and (max-width: 440px) {
  .rcs_accordion_sec {
    width: 300px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 177px !important; */
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 175px;
  }
}

@media (min-width: 441px) and (max-width: 500px) {
  .rcs_accordion_sec {
    width: 300px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 190px !important; */
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 190px;
  }
}

@media (min-width: 501px) and (max-width: 575px) {
  .rcs_accordion_sec {
    width: 300px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 213px !important; */
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 216px;
  }

  .rcs_product_grid .rcs_prod_info {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 300px) {

  body .imgWrapperWatch {
    padding: 20px 10px;
    height: auto;
  }

  a.imgWrapperWatch img {
    height: 100%;
  }

  .watchList .rcs_product_info {
    padding-top: 0px;
  }

  .rcs_view.mobPb15.mb-5 {
    margin-bottom: 10px !important;
  }

  .mobPb15 {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }

  .rcs_view.mobPb15 div {
    justify-content: center;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .rcs_accordion_sec {
    width: 300px;
    padding: 10px 20px;
  }

  .rcs_accordion_item div#panel2a-content {
    max-height: 100%;
  }

  .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 100%;
  }

  .rcs_product_item {
    margin-bottom: 0px;
  }

  .rcs_prod_info {
    padding-top: 45px;
    position: relative;
  }

  button.rcs_wish_btn svg {
    display: none;
  }

  .rcs_view div .rcs_mob_filter {
    padding: 0;
    width: 50%;
    cursor: pointer;
    padding: 13px 0;
  }

  .rcs_view div .rcs_mob_filter1 {
    padding: 0;
    width: 50%;
    padding: 13px 0;
  }

  .rcs_view {
    padding: 0;
  }

  .rcs_mob_filter {
    display: block !important;
  }

  .rcs_view div {
    display: flex;
    justify-content: center;
  }

  .rcs_accordion_sec .rcs_accordion_item {
    position: unset;
  }
}

@media (min-width: 576px) and (max-width: 600px) {
  .rcs_product_grid .rcs_product_item a {
    height: 245px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 240px;
  }
}

@media (min-width: 601px) and (max-width: 630px) {
  .rcs_product_grid .rcs_product_item a {
    height: 270px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 265px;
  }
}

@media (min-width: 631px) and (max-width: 660px) {
  .rcs_product_grid .rcs_product_item a {
    height: 290px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 285px;
  }
}

@media (min-width: 661px) and (max-width: 690px) {
  .rcs_product_grid .rcs_product_item a {
    height: 310px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 305px;
  }
}

@media (min-width: 691px) and (max-width: 750px) {
  .rcs_product_grid .rcs_product_item a {
    height: 330px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 325px;
  }
}

@media (min-width: 691px) and (max-width: 750px) {
  .rcs_product_grid .rcs_product_item a {
    height: 330px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 325px;
  }
}

@media (min-width: 751px) and (max-width: 767px) {
  .rcs_product_grid .rcs_product_item a {
    height: 340px !important;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 335px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebarWrapper .rcs_price_filter .MuiFormControlLabel-root .MuiTypography-body1 {
    font-size: 12.5px;
  }

  .rcs_product_grid .rcs_product_item a {
    /* height: 220px !important; */
  }

  .rcs_product_item img {
    max-height: min-content !important;
    height: 150px !important;
  }

  .remPadLg .rcs_filter_list h5 span {
    font-size: 15px;
  }

  .sidebarWrapper .rcs_price_filter .MuiFormControlLabel-root>span:first-child {
    padding-right: 3px;
  }

  .sidebarWrapper .rcs_price_filter .rcs_price_filter .MuiTypography-body1 .rcs_product_grid .rcs_product_item a {
    height: 200px !important;
  }

  .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 100%;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 200px;
  }

  .rcs_product_grid .rcs_product_item {
    margin-bottom: 0px;
  }

  .rcs_product_grid .rcs_prod_info {
    padding-top: 20px;
  }

  .rcs_product_grid .rcs_prod_info h2 {
    font-size: 12px;
  }

  .rcs_product_grid button.rcs_wish_btn svg {
    height: 22px;
    width: 22px;
  }

  .rcs_product_grid .rcs_list_slider button.slick-arrow.slick-prev,
  .rcs_product_grid .rcs_list_slider button.slick-arrow.slick-next {
    width: 30px;
    height: 30px;
  }

  .rcs_accordion_sec .rcs_accordion_item {
    position: unset;
  }

  .rcs_view div .rcs_mob_filter {
    padding: 0;
    /* width: 50%; */
    cursor: pointer;
    padding: 13px 0;
  }

  .rcs_view div .rcs_mob_filter1 {
    padding: 0;
    width: 50%;
    padding: 13px 0;
  }

  .rcs_view {
    padding: 0;
  }

  .rcs_mob_filter {
    display: block !important;
  }

  .rcs_view div {
    display: flex;
    /* justify-content: center; */
  }

  .rcs_accordion_sec {
    width: 300px;
    padding: 10px 20px;
  }

  .rcs_accordion_item div#panel2a-content {
    max-height: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 100%;
  }

  .rcs_product_grid .slick-slider.rcs_list_slider {
    width: 290px;
  }

  .rcs_product_grid .rcs_product_item {
    margin-bottom: 0px;
  }

  .rcs_product_grid .rcs_prod_info {
    padding-top: 15px;
  }

  .rcs_product_grid .rcs_prod_info h2 {
    font-size: 14px;
  }

  .watchList.rcs_product_item img, .\/jewelry\/fine-jewelry.rcs_product_item img, .\/jewelry\/fashion-rings.rcs_product_item img, .\/jewelry\/pendants.rcs_product_item img, .\/jewelry\/necklaces.rcs_product_item img, .\/jewelry\/earrings.rcs_product_item img, .\/jewelry\/bracelets.rcs_product_item img {
    height: 217px;
  }

  .rcs_product_grid button.rcs_wish_btn svg {
    height: 22px;
    width: 22px;
  }

  .rcs_product_grid .rcs_list_slider button.slick-arrow.slick-prev,
  .rcs_list_slider button.slick-arrow.slick-next {
    width: 30px;
    height: 30px;
  }

  .rcs_accordion_sec {
    width: 300px;
    padding: 10px 20px;
  }

  .rcs_accordion_item div#panel2a-content {
    max-height: 100%;
  }

  .rcs_accordion_sec .rcs_accordion_item {
    position: unset;
  }
}

/*product list grid css*/
/*product list list item css*/
@media (min-width: 320px) and (max-width: 1199px) {
  .rcs_prodlist_item .rcs_list_slider button.slick-arrow.slick-next {
    /* top: 93%; */
    right: 0;
  }

  .rcs_prodlist_item .rcs_list_slider button.slick-arrow.slick-prev {
    /* top: 93%; */
    left: 0;
  }

  .rcs_prodlist_item .slick-slider.rcs_list_slider {
    width: 100%;
  }

  .rcs_prodlist_item .rcs_prod_list_info .rcs_list_title {
    font-size: 13px;
    text-align: left;
  }

  .rcs_prodlist_item {
    margin: 10px 0 0px 0;
    padding: 20px 0 10px;
  }

  .rcs_prodlist_item button.rcs_wish_btn svg {
    height: 22px;
    width: 22px;
  }

  .rcs_prodlist_item .rcs_list_img button.rcs_wish_btn {
    right: 3px;
    top: 0px;
  }

  /* .rcs_prodlist_item .rcs_product_item a {
      height: 112px;
      width: 100%;
  } */
  .rcs_prodlist_item .rcs_product_item a {
    height: 100%;
    width: 100%;
  }

  .rcs_prodlist_item .rcs_list_img {
    border: 0;
    max-width: 230px;
    height: auto;
    min-height: 100%;
  }

  .mg_nopad.col-md-4.col-sm-5.col-5 {
    padding: 0 15px;
  }

  .rcs_prod_list_info {
    margin-left: 0px;
  }

  .rcs_prod_list_info .rcs_list_title {
    margin-top: 0;
    font-size: 14px;
  }

  .rcs_prod_list_info .rcs_product_item img {
    width: 100px;
    margin: 0 auto;
    display: block;
  }

  .rcs_prodlist_item .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 100% !important;
    border: 0px;
  }

  .rcs_list_img button.rcs_wish_btn {
    display: block;
    border: 0px;
    background: none;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 99;
  }

  .rcs_main_price {
    margin: 5px 0 10px 0;
    font-size: 13px;
  }

  .rcs_dblock button {
    padding: 0;
    width: 100%;
    justify-content: start;
  }

  .rcs_prodlist_item {
    margin: 10px 0 0px 0;
    padding: 20px 0 10px;
  }

  .rcs_view div .rcs_mob_filter {
    padding: 0;
    /* width: 50%; */
    cursor: pointer;
    /* padding: 13px 0; */
  }

  .rcs_view div .rcs_mob_filter1 {
    padding: 0;
    /* width: 50%; */
    padding: 13px 0;
  }

  .rcs_view {
    padding-top: 20px;
  }

  .rcs_mob_filter {
    display: block !important;
  }

  .rcs_view div {
    display: flex;
    /* justify-content: center; */
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .rcs_accordion_item div#panel2a-content {
    overflow-y: auto;
    height: auto;
    max-height: 320px;
  }

  .rcs_accordion .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.rcs_accordion_item.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    border-bottom: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1920px) {

  .rcs_product_item:hover .rcs_list_slider button.slick-arrow.slick-prev,
  .rcs_product_item:hover .rcs_list_slider button.slick-arrow.slick-next {
    display: block !important;
  }

  .rcs_list_slider button.slick-arrow.slick-next,
  .rcs_list_slider button.slick-arrow.slick-prev {
    display: none !important;
  }
}

/*product list list item css*/
/*Responsive css*/

/* .rcs_front_list_img123 img.rcs_back_list_img {
display: none !important;
}

.rcs_front_list_img123 .rcs_front_list_img:hover {
display: none !important;
}
.rcs_front_list_img123 .rcs_front_list_img:hover ~ .rcs_front_list_img123 .rcs_back_list_img {
display: block !important;
} */
.fs30 {
  font-size: 30px;
}

@media only screen and (min-width: 1100px) {
  .remPadLg {
    padding-left: 0px;
  }

}

.drawerX {
  padding-left: 15px;
  padding-right: 15px;
}

.noBtn {
  background: transparent;
  border: none;
  outline: none;
}

.MuiDrawer-paperAnchorLeft {
  max-width: 85%;
  /* background-color: #f5f8f9 !important; */
  
}

.parentListWatch>div {
  height: 100%;
  margin-bottom: 0px;
  position: relative;
}

.parentListWatch {
  margin-bottom: 30px;
}

.sidebarWrapper {
  position: sticky;
  top: 72px;
}

/* .ringSettingHoverBox {
  opacity: 1;
  position: absolute;
  bottom: 65px;
  z-index: 1;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  display: flex !important;
  justify-content: center;
} */

.ringSettingHoverBoxWishlist {
  display: flex;
  justify-content: center;
}

.ringSettingHoverBox .ringSettingHoverBoxWishlist svg {
  width: 50px;
  height: 50px;
  margin: 2px -8px;
  cursor: pointer;
}

/* .rcs_product_item:hover .ringSettingHoverBox {
  opacity: 1;
  z-index: 99;
} */

.rcs_product_item {
  overflow: hidden;
}

/***** Slide Right *****/
.rcs_product_item:hover .ringSettingHoverBox .ringSettingHoverBoxWishlist:first-of-type {
  animation: .5s slide-right;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

/***** Slide Left *****/
.rcs_product_item:hover .ringSettingHoverBox .ringSettingHoverBoxWishlist:nth-of-type(2) {
  /* animation: .5s slide-left; */
  /* position: relative; */
}

@keyframes slide-left {
  from {
    right: -100%;
  }

  to {
    right: 0%;
  }
}

.gemstonList_Filter_type {
  /* width: 100% !important; */
}

@media (min-width:991px) and (max-width:1279px) {
  .remPadLg.px-5 {
    padding: 0 15px !important;
  }

  li.rcs_price_range_input1 {
    width: 107px !important;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap;
  }

  .selectedOptions {
    font-size: 13px !important;
  }
}

@media(max-width: 768px) {
  .rcs_diamonds_grid_content img {
    height: 170px !important;
  }

  .rcs_diamonds_grid_content .rcs_product_info h3 {
    font-size: 13px;
    margin-bottom: 0;
  }

  .rcs_diamonds_grid_content .rcs_product_info p {
    font-size: 16px;
  }

  .rcs_sort_filter .form-select {
    /* max-width: 170px; */
  }


  .rcs_product_item a {
    justify-content: center;
  }

  .rcs_product_item img {
    width: 100%;
    max-height: 250px;
    margin: 0;
  }

  .rcs_product_item {
    position: relative;
  }

  .hover-buttons {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@media (min-width:1280px) and (max-width:1439px) {
  .rcs_prod_info h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .rcs_prod_info p {
    font-size: 16px;
  }

  .rcs_prod_info {
    padding: 8px;
  }

  .ringSettingHoverBox .ringSettingHoverBoxWishlist svg {
    width: 35px;
    height: 35px;
    margin: 4px -6px;
    cursor: pointer;
  }

  .watchList.rcs_product_item img, .\/jewelry\/fine-jewelry.rcs_product_item img, .\/jewelry\/fashion-rings.rcs_product_item img, .\/jewelry\/pendants.rcs_product_item img, .\/jewelry\/necklaces.rcs_product_item img, .\/jewelry\/earrings.rcs_product_item img, .\/jewelry\/bracelets.rcs_product_item img {
    height: 200px;
  }
}

@media(min-width:991px) {
  .wedding_band_product_table .rcs_product_item img {
    height: 150px !important;
    width: 100%;
    object-fit: contain;
  }

  .wedding_band_product_table .rcs_prod_list_info .rcs_list_title {
    margin-top: 0;
  }
}

@media(max-width:990px) {
  .rcs_accordion_sec .rcs_accordion_item {
    background-color: #fff;
  }
  .wedding_band_product_table .rcs_product_item img {
    height: auto;
  }

  .wedding_band_product_table .pro_imgg_outer_boxx {
    min-height: auto;
  }

  .Eng_Ring_Table_View .rcs_product_item img {
    height: auto;
  }

  .Eng_Ring_Table_View .rcs_dblock button {
    text-align: left;
  }
}
@media (min-width:1440px) and (max-width:1900px) {
.watchList.rcs_product_item img, .\/jewelry\/fine-jewelry.rcs_product_item img, .\/jewelry\/fashion-rings.rcs_product_item img, .\/jewelry\/pendants.rcs_product_item img, .\/jewelry\/necklaces.rcs_product_item img, .\/jewelry\/earrings.rcs_product_item img, .\/jewelry\/bracelets.rcs_product_item img {
  height: 220px;
}
}
@media (min-width:768px) and (max-width:992px) { 
  .mg_nopad {
      padding: 0px 15px;
  }
}

@media (min-width:1900px){
.Stud_diamonds_rcs_color_slider_desk {
  position: relative;
  top: 26px;
}
}

/* Horizontal Filter css */
@media (min-width:1440px) and (max-width:1900px) {
.horizontalFilter .col-xl-4.col-lg-6.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}
.horizontalFilter .rcs_cut_slider_diamond {
  position: relative;
  top: 0px;
}
.Stud_diamonds_rcs_color_slider{
  position: relative;
  top: 26px;
}
}

@media (min-width:1280px) and (max-width:1439px) {
.horizontalFilter .col-xl-4.col-lg-6.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}
.horizontalFilter .rcs_cut_slider_diamond {
  position: relative;
  top: 0px;
}
.Stud_diamonds_rcs_color_slider{
  position: relative;
  top: 26px;
}
}

@media (min-width:991px) and (max-width:1279px) {
.horizontalFilter .col-xl-4.col-lg-6.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}
.horizontalFilter .rcs_cut_slider_diamond {
  position: relative;
  top: 0px;
}
.Stud_diamonds_rcs_color_slider{
  position: relative;
  top: 26px;
}
}

.rcs_accordion_item {
  background-color: transparent !important;
}

ul.lab-wrapper-slider li.lab-certificate-filter {
  margin: 0 5px;
  max-width: 100px;
  border-radius: 0;
  border: 1px solid #ddd !important;
  padding: 10px;
  font-weight: 600;
}

ul.lab-wrapper-slider li.lab-certificate-filter.item--YBPgi.selected--Qs8si {
  border: 1px solid var(--inner-primary) !important;
}


/* toggle on filter pages style */
.show_toggle_icon_box_inner svg {
  width: 18px;
  height: auto;
  color: var(--white);
  transition: all .4s;
}
.add_transform_n_width {
  transform: translate(-600px, 10px);
  /* transition: all 0.4s; */
  width: 0% !important;
  margin-right: 0 !important;
  display: none;
}
.show_toggle_icon_text {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  width: max-content;
  background-color:  var(--inner-primary);
  border: 1px solid  var(--inner-primary);
  font-weight: 400;
  color: var(--inner-primary);
  border-radius: 6px;
  font-size: 13px;
  transition: all .3s ease-in-out;
  font-family: var(--font_2);
  letter-spacing: .5px;
  min-width: 150px;
  color: var(--white);
}
.show_toggle_icon_text:hover, .show_toggle_icon_text:focus, .show_toggle_icon_text:active {
  background-color: var(--inner-primary);
  color: #fff;
}
.show_toggle_icon_text:hover svg, .show_toggle_icon_text:focus svg, .show_toggle_icon_text:active svg{
  color: var(--white);
}
.transition_add{
  transition: all 0.3s ease-in-out;
}