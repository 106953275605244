/******* diamond for less header css ********/
.rcs_diamonds_content_fine ul {
  visibility: visible !important;
  position: inherit !important;
  display: grid !important;
  width: 100%;
  background: transparent !important;
  box-shadow: none !important;
  grid-template-columns: auto auto auto;
}
#rcs_header_seaction {
  border-bottom: 0;
}
.rcs_diamonds_content_inner.rcs_diamonds_content_inner_bottom.dfl_shop_shape_menu_content1 {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  gap: 3px 0px;
}

.rcs_search_bar.dfl_top_search {
  width: 100%;
  position: relative;
  padding-left: 10px;
  padding-right: 0;
}
.rcs_after_login_button span.rcs_mobile {
  /* border: 1px solid #5a5a5a;
  border-radius: 60%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal; */
}
.dfl_top_right_cont_main {
  display: flex;
  justify-content: flex-end;
}

.dfl_new_header_top_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dfl_header_logo img.dfl_logo_top_m {
  max-width: 100%;
}

.rcs_right_side.dfl_right_icn_content ul li:nth-last-child(1) {
  margin-right: 0;
}

.rcs_right_side.dfl_right_icn_content ul li {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.rcs_right_side.dfl_right_icn_content ul li img{
  margin-right: 5px;
}
.rcs_logo_head .dfl_header_logo_top {
  display: none;
}

.rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_logo_top {
  display: block;
  /* padding-bottom: 8px; */
}

.dfl_header_logo_top img {
  max-width: 100%;
}
.rcs_logo_head.rcs_sticky-top.sticky-top img {
  max-width: 85%;
}

.dfl_sticky_logo {
  display: none;
}

.rcs_logo_head.rcs_sticky-top.sticky-top .dfl_sticky_logo {
  display: block;
}

.dfl_top_right_number a {
  color: #767676;
  font-size: 12px;
}
.dfl_top_right_number a small{
  text-transform: lowercase;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}
/*********************************************

/*Top Header css*/
.rcs_top_head_content {
  position: relative;
  padding: 3px 0;
}

.rcs_top_head_content p {
  text-align: center;
  margin-bottom: 0;
  border-radius: 0;
  padding: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
}

.rcs_top_head_content p a {
  text-decoration: underline;
  padding-left: 5px;
}



.rcs_dropdown_lang span {
  padding-right: 5px;
  font-size: 13px;
  font-weight: 500;
}

.rcs_dropdown_lang button,
.rcs_dropdown_lang button:hover,
.rcs_dropdown_lang.show button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #000 !important;
  padding: 0 !important;
}

.rcs_right_side_mobile ul li a {
  font-size: 12px;
  padding: 0 10px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.rcs_right_side_mobile ul li a img {
  width: 16px;
  margin-right: 5px;
}

.rcs_right_side_mobile ul li .dropdown-menu {
  z-index: 9999;
  min-width: 6rem !important;
}

/*Top Header css*/
/*Header css*/
.rcs_left_side ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rcs_left_side ul li {
  padding: 0 12px;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.075rem;
  padding-left: 0px;
  cursor: pointer;
}

.rcs_left_side ul li i {
  border-radius: 50%;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

.rcs_right_side ul {
  display: flex;
  justify-content: end;
  align-items: center;
}

.rcs_right_side ul li {
  padding: 0;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.075rem;
  position: relative;
}

.rcs_right_side ul li svg {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  color: #202020 !important;
}

.rcs_wishlist_icon {
  width: 15px;
  cursor: pointer;
  vertical-align: middle;
  margin: 0px 3px;
}

.rcs_right_side li:nth-child(5) {
  margin-left: 7px;
}

.rcs_right_side li:nth-child(4) img {
  width: 15px;
}

.rcs_right_side li:nth-child(1) img {
  width: 17px;
  opacity: 0.7;
}

.rcs_header {
  padding: 0px 0px 0px;
  /* background: #e2e9e9; */
  box-shadow: none;
}

.rcs_logo_section img {
  width: 200px;
  margin: 0px auto 20px;
  display: block;
  cursor: pointer;
}

span.rcs_cart_badge,
span.rcs_wish_badge {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  display: block;
  position: static;
  background-color: transparent;
  color: #000;
}

.rcs_after_login_button {
  text-transform: capitalize;
}

.rcs_search_bar input {
  width: 100%;
  border-radius: 0px;
  font-size: 16px;
}

.rcs_search_bar input:focus {
  box-shadow: none;
  outline: none;
}

.rcs_search_bar input:hover {
  box-shadow: none;
  outline: none;
}

.rcs_search_bar input {
  width: 100%;
}

.rcs_search_bar svg:last-child {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  margin: auto 0;
}

/* .rcs_search_bar svg:first-child {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  margin: auto 0;
} */

.rcs_search_modal .rcs_search_bar {
  position: absolute;
  width: 25%;
  right: 310px;
  z-index: 9;
  top: 80px;
}

.rcs_search_modal12 .rcs_search_bar {
  position: absolute;
  width: 25%;
  right: 310px;
  z-index: 9;
  top: 45px;
}

@media (min-width: 1200px) {
  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo {
    /* padding-top: 10px; */
  }
}

@media (max-width: 1023px) {
  .dfl_top_right_number {
    display: none;
  }
  .dfl_top_right_cont_main {
    display: inherit;
    justify-content: inherit;
  }
}

@media (min-width: 576px) {
  .rcs_search_modal .modal-dialog,
  .rcs_search_modal12 .modal-dialog {
    max-width: 100%;
  }
}

.rcs_search_bar input {
  padding-left: 10px;
  font-size: 14px;
  height: 33px;
  background-color: #fff !important;
}

.rcs_right_side ul li .MuiInputBase-input {
  border-radius: 2px;
}

.rcs_menu {
  position: relative;
  z-index: 998;
}

#rcs_menu_header_top {
  width: 100%;
}

#rcs_menu_header_top ul.rcs_header_menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
}

.rcs_logo_head.rcs_sticky-top.sticky-top
  #rcs_menu_header_top
  ul.rcs_header_menu {
  justify-content: center;
}

#rcs_menu_header_top ul {
  list-style: none;
  /* position:relative;
        float:left;
        margin:0;
        padding:0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
  /* display: block;
  text-decoration: none;
  font-size: 12.5px;
  line-height: 32px;
  padding: 0 10px 11px;
  transition: all 200ms ease-in 50ms !important;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: none;
  margin: 0;
  font-weight: 600; */
}

#rcs_menu_header_top ul span a {
  text-transform: capitalize;
}

#rcs_menu_header_top ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 20px 0 0;
}

/* #rcs_menu_header_top ul.rcs_header_menu li:first-child{
        position: unset;
    }
    #rcs_menu_header_top ul.rcs_header_menu li:nth-child(2){
        position: unset;
    } */
#rcs_menu_header_top ul h6 {
  position: relative;
  margin: 0;
}

#rcs_menu_header_top ul li:hover h6::after {
  width: 100% !important;
}

#rcs_menu_header_top ul li h6::after {
  content: '' !important;
  display: block !important;
  width: 0 !important;
  height: 2px !important;
  transition: 0.1s width ease !important;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  color: #212123;
  background-color: #000;
  margin: 0 auto;
  z-index: 99999;
}

/* #rcs_menu_header_top ul.rcs_header_menu li.rcs_top_header_menu:first-child h6:after,
    #rcs_menu_header_top ul.rcs_header_menu li.rcs_top_header_menu:nth-child(2) h6:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #d5d5d5;
        position: absolute;
        top: 33px;
        transform: rotate(-90deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        transition: all .8s;
    } */
#rcs_menu_header_top ul ul {
  display: none;
  position: absolute;
  /* top:100%; */
  top: 63px;
  left: 0;
  padding: 5px 0;
  z-index: 999;
}

.mask-overly {
  content: '';
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 106px;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
}

#rcs_menu_header_top ul li:hover > .mask-overly {
  display: block !important;
}

.rcs_logo_head.rcs_sticky-top .mask-overly {
  /* top: 70px; */
}

.rsc_mega_menu_ul1 {
  top: 25px !important;
}

#rcs_menu_header_top ul.rcs_header_menu ul.rsc_mega_menu_ul {
  width: 100%;
}

#rcs_menu_header_top ul ul li {
  position: relative;
  float: none;
  width: auto;
  padding: 0 0px;
}

#rcs_menu_header_top ul ul li.rcs_menu_header_top {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#rcs_menu_header_top ul ul span a {
  padding: 10px 15px;
  font-size: 12px;
}

#rcs_menu_header_top ul ul ul {
  top: 0;
  left: 100%;
}

#rcs_menu_header_top ul li:hover > ul {
  display: block;
}

.MuiAccordionDetails-root.rcs_mobile_inner_menu1 {
  padding: 0 !important;
}

.rcs_logo_head.rcs_sticky-top {
  /* position: fixed;
  top: -2px;
  z-index: 1020;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
  width: 100%;
  transition: all 0.2s ease-in-out 0s; */
}

.rcs_search_modal .modal-content,
.rcs_search_modal12 .modal-content {
  border: none !important;
}

#rcs_menu_header_top ul li:nth-child(5) ul li {
  width: 150px;
}

/*Header css*/
/*Login modal css*/
.rcs_appointment_input.rcs_login button {
  width: 100%;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  border-radius: 0 !important;
}

.rcs_login_modal .modal-header {
  border: none;
}

.rcs_login div#contained-modal-title-vcenter {
  text-align: center;
  padding: 0 0 50px 0;
  text-transform: uppercase;
  font-weight: 600;
}

.rcs_forgot div#contained-modal-title-vcenter {
  padding: 20px 0;
}

.rcs_login {
  padding: 0 40px 40px;
}

.rcs_google_signup_main button {
  justify-content: center;
  margin-top: 15px;
}

.rcs_appointment_input.rcs_login button span {
  line-height: 1;
}

.rcs_login a {
  padding: 0;
  float: right;
  padding-bottom: 7px;
  font-size: 12px;
}

a.rcs_login_forgot.rcs_forogt_pass {
  padding-bottom: 15px;
  font-size: 12px;
  margin-top: -10px;
}

.MuiOutlinedInput-input {
  padding: 13.5px 14px;
}

.rcs_signup_link p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 0;
  padding: 40px 0 0;
}

.rcs_signup_link a {
  font-size: 16px;
  font-weight: 600;
  padding: 0 4px;
}

.rcs_check_condition .MuiFormControlLabel-root {
  margin: 0;
  width: 12%;
  float: left;
  justify-content: center;
  align-items: center;
}

.rcs_check_condition {
  margin: 15px 0;
}

.rcs_check_condition span {
  font-size: 12px;
  text-transform: capitalize;
}

.rcs_check_condition span a {
  text-decoration: underline;
  font-weight: 700;
}

.rcs_check_condition .col {
  padding: 0;
}

.rcs_condition {
  position: relative;
  top: 8px;
}

.rcs_appointment_input .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin-bottom: 20px;
}

.rcs_forgot_msg {
  text-align: center;
  padding-bottom: 20px;
  font-size: 11px;
  font-weight: 500;
}

.rcs_menu_list_item ul {
  padding: 12px 10px;
}

.rcs_menu_list_item ul li {
  display: block;
  padding: 5px 10px;
}

/*Login modal css*/

/*Register Page Css*/
.rcs_account_content_left {
  padding: 20px;
  border-radius: 3px;
}

.rcs_account_content_left .MuiFormControl-root {
  width: 100%;
  margin-bottom: 15px;
}

.rcs_custom_padding {
  padding: 0 5px !important;
}

.alert.alert-info.text-left {
  margin-top: 20px;
  padding: 20px;
}

.alert.alert-info.text-left span {
  padding-left: 5px;
  margin-top: -2px;
}

/*Register Page Css*/
.rcs_signup_link_error {
  position: relative;
}

.rcs_signup_link_error p {
  position: absolute;
  top: 0;
  padding: 10px 0 0;
  font-size: 12.5px;
  left: 0;
  right: 0;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list
  li {
  display: block !important;
  padding: 10px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left !important;
}

/*Mega Menu Css*/
.react-slidedown.my-dropdown-slidedown {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.rcs_dropdown_menu {
  position: absolute;
  top: 17px;
  left: 0;
  width: 100%;
  animation: rcs_dropdown_menu 0.7s ease;
  -webkit-animation: rcs_dropdown_menu 0.7s ease;
  -moz-animation: rcs_dropdown_menu ease 0.7s;
  -o-animation: rcs_dropdown_menu ease 0.7s;
  -ms-animation: rcs_dropdown_menu ease 0.7s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  z-index: 9;
}

.rcs_dropdown_cotnent {
  display: flex;
  width: 100%;
  padding: 25px 0;
  box-shadow: 1px 1px 3px 0 rgb(100 100 100 / 20%);
}

.rcs_customer_container1.rcs_dropdown_inner_cotnainer.container {
  align-items: unset;
  justify-content: unset;
}

.rcs_customer_container1 {
  width: 100%;
  max-width: 100% !important;
  margin: 0 auto;
  padding: 0 10px !important;
}

.rcs_dropdown_inner_cotnainer {
  width: 100%;
  max-width: 1440px !important;
  margin: 0 auto;
  padding: 0 10px !important;
}

@keyframes rcs_dropdown_menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes rcs_dropdown_menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes rcs_dropdown_menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes rcs_dropdown_menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes rcs_dropdown_menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rcs_inner_dropdown_menu h2 {
  font-size: 18px;
  margin: 15px 0 0;
  font-weight: 800;
  cursor: pointer;
}

.rcs_inner_dropdown_menu img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.rcs_top_header_menu h6 a svg {
  width: 20px;
  height: 20px;
  color: #212123;
  margin-top: -5px;
}

#rcs_menu_header_top ul h6 a span {
  color: transparent;
}

/*Mega Menu Css*/
/*New Header Css*/
/* .rcs_top_head_content_right {
        position: absolute;
        top: 0;
        right: 30px;
    } */
.rcs_top_head_content_right ul {
  display: flex;
  justify-content: start;
  align-items: center;
}

.rcs_top_head_content_right ul li:first-child {
  border-right: 1px solid rgb(255, 255, 255);
  padding: 0 10px;
}

.rcs_top_head_content_right ul li span {
  border-radius: 3px;
  padding: 3px 5px 6px;
}

.rcs_top_head_content_right ul li svg {
  width: 16px;
  height: 16px;
  color: #ffffff;
}

.rcs_top_head_content_right ul li {
  padding: 3px 5px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
  border-radius: 50% !important;
  margin: 0;
}


.rcs_blank_button .rcs_mobile svg {
  display: none;
}

/*New Header Css*/

/********** rcs mobile menu contact us css start *********/
.contact-section .rcs_circle {
  margin-top: 5px;
  display: inline-block;
  background: #666 !important;
  width: 38px;
  height: 38px;
  margin-right: 10px;
}

.rcs_circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f1d6d3;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.contact-section .rcs_circle a {
  line-height: 38px;
  color: #fff;
  padding: 0px;
  font-size: 15px;
  border: none;
}

.rcs_extra_link.contact-section.mobile_contact {
  margin-top: 15px;
}

.rcs_circle a p {
  color: #666;
  text-transform: capitalize;
  font-size: 12.5px;
}

.rcs_extra_link p {
  margin-bottom: 5px;
}

/********** rcs mobile menu contact us css end *********/
div#google_translate_element .skiptranslate {
  font-size: 0;
}

div#google_translate_element span {
  display: none;
}

/*bottom css page*/

#rcs_menu_header_top ul li:hover > ul li:nth-first-child(1) {
  padding: 10px 0;
}

#rcs_menu_header_top ul li:hover > ul {
  display: block;
  visibility: visible;
  transition: all 0.4s cubic-bezier(0.2, 0.06, 0.05, 0.95);
  overflow: hidden;
  transform: translateY(0px);
  width: max-content;
}

#rcs_menu_header_top ul ul {
  display: block;
  position: absolute;
  /* top: 100%; */
  left: 0;
  padding: 0px 0;
  z-index: 999;
  height: auto !important;
  visibility: hidden;
  box-shadow: 0 10px 20px #00000017;
  transform: translateY(-15px);
}

#rcs_menu_header_top ul li:hover > ul li span a {
  transform: translate(0) !important;
  transition: all 0.4s cubic-bezier(0.2, 0.06, 0.05, 0.95) !important;
}

#rcs_menu_header_top ul ul li span a {
  transform: translateY(-18px) !important;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
  transition: none !important;
}

#rcs_menu_header_top ul h6 a {
  height: 65px;
  line-height: 65px !important;
}

#rcs_menu_header_top ul li:hover > ul li:first-child {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

/*bottom css page*/

/*New Mega menu css*/
.rcs_menu {
  position: unset;
  z-index: 998;
}

#rcs_menu_header_top {
  width: 100%;
}

#rcs_menu_header_top ul li {
  position: unset;
  float: left;
  margin: 0;
  padding: 0;
}

#rcs_menu_header_top ul h6 {
  position: relative;
  margin: 0;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
  transition: none !important;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
  color: #000;
  text-align: center;
  display: block;
  text-decoration: none;
  font-size: 12.5px;
  /* line-height: 32px; */
  padding: 0 10px 0;
  transition: all 200ms ease-in 50ms !important;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: none;
  margin: 0;
  font-weight: 600;
  line-height: 2.5;
}

#rcs_menu_header_top ul li:hover h6::after {
  width: 100% !important;
}

#rcs_menu_header_top ul h6 a span {
  color: transparent;
}


.rcs_logo_head.rcs_sticky-top.sticky-top
  #rcs_menu_header_top
  ul
  h6
  a
  span:after {
  content: '';
  top: 55px;
}

#rcs_menu_header_top ul h6 a span:after {
  content: '';
  display: none;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 13px solid #fff;
  position: absolute;
  top: 32px;
  transform: rotate(-90deg);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  transition: all 0.8s;
  z-index: 999;
}

#rcs_menu_header_top ul li:hover h6 a span:after {
  display: block;
}

.rcs_diamonds_content h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 0 5px;
}

.rcs_diamonds_content_inner img {
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px 0 0;
  object-fit: contain;
}


.rcs_diamonds_content_inner a,
.navReplacement {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
}

.rcs_diamonds_content_inner_bottom a {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  width: 130px;
}

.rcs_diamonds_content p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.rcs_diamonds_ring_inner {
  position: relative;
}

.rcs_diamonds_ring_inner img {
  width: 100%;
}

.rcs_diamonds_ring_inner h4 {
  position: absolute;
  bottom: 20px;
  color: #000;
  text-align: center;
  padding: 0 10px;
  font-size: 18px;
  line-height: 26px;
  width: 70%;
  right: 0;
  border-left: 2px dotted #e1e1e1;
}

.cust_padd_diamonds_ring {
  /* padding: 0 5px; */
  padding: 5px;
}

.cust_padd_diamonds_shape {
  padding: 0 5px;
}

.rcs_diamonds_content {
  padding: 30px 20px;
}

.rcs_diamonds_content1 img {
  width: 100%;
  height: 46.5vh;
  object-fit: cover;
}

.rcs_diamonds_content1 {
  /* background-image: url('../images/Megamenu/diamonds/right_sider_diamonds.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 454px;
}

.rcs_gemstone_content1 {
  /* background-image: url('../images/Megamenu/gemstone/genstones.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 540px;
}

.rcs_engagRing_content1 {
  /* background-image: url('../images/Megamenu/engagement/engagement_rings.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 540px;
}

.rcs_weddingRing_content1 {
  /* background-image: url('../images/Megamenu/wedding/wedding_rings.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 540px;
}

.rcs_fineJewelry_content1 {
  /* background-image: url('../images/Megamenu/fine_jewelry/fine_jewelry.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 540px;
}

.rcs_diamonds_content1_inner {
  position: absolute;
  bottom: 40px;
  left: 30px;
}

.rcs_gemstone_content1_inner,
.rcs_engagRing_content1_inner,
.rcs_weddingRing_content1_inner,
.rcs_fineJewelry_content1_inner {
  position: absolute;
  top: 25%;
  left: 30px;
}

.rcs_diamonds_content1_inner h5,
.rcs_gemstone_content1_inner h5,
.rcs_engagRing_content1_inner h5,
.rcs_weddingRing_content1_inner h5,
.rcs_fineJewelry_content1_inner h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.rcs_diamonds_content1_inner h2,
.rcs_gemstone_content1_inner h2,
.rcs_engagRing_content1_inner h2,
.rcs_weddingRing_content1_inner h2,
.rcs_fineJewelry_content1_inner h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.rcs_diamonds_content1_inner p,
.rcs_gemstone_content1_inner p,
.rcs_engagRing_content1_inner p,
.rcs_weddingRing_content1_inner p,
.rcs_fineJewelry_content1_inner p {
  font-size: 14px;
  font-weight: 500;
}

.rcs_gemstone_content1_inner button,
.rcs_engagRing_content1_inner button,
.rcs_weddingRing_content1_inner button,
.rcs_fineJewelry_content1_inner button {
  margin-bottom: 15px;
}

.rcs_gemstone_content1_inner a,
.rcs_engagRing_content1_inner a,
.rcs_weddingRing_content1_inner a,
.rcs_fineJewelry_content1_inner a {
  font-size: 14px;
  color: #000;
  margin: 5px 0;
  font-weight: 500;
  text-decoration: underline;
}

.rcs_engangement_ring {
  margin-bottom: 8px;
  width: 100%;
}

.rcs_style_content_inner img {
  width: 60px !important;
}

.rcs_metal_content_inner img {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  margin: 0 10px 0 0 !important;
}

.rcs_fine_img {
  position: absolute;
  top: 80px;
  right: 30px;
}

.rcs_fine_img {
  position: absolute;
  top: 80px;
  right: 30px;
}

.rcs_diamonds_content ul {
  margin: 0;
  padding: 0;
}

.rcs_sticky-top.sticky-top .hover_overlayer {
  height: calc(100% - 60px);
}

/* .rcs_diamonds_content ul li {
  background-color: #f2f2f2;
  width: 120px;
  height: 120px;
  text-align: center;
  margin: 4px !important;
  padding: 18px !important;
  cursor: pointer;
  border-radius: 5px;
}

.rcs_diamonds_content ul li:hover {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.rcs_diamonds_content ul li img {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: 7px;
}

.rcs_gemstone_content_inner img {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.rcs_diamonds_content ul li h2 {
  font-size: 14px;
  margin: 0;
  position: relative;
  color: #000;
  top: 15px;
  text-transform: capitalize;
}

#rcs_menu_header_top ul li:last-child h6 a span:after {
  display: none !important;
} */

/*fixed header css for menu*/


.rcs_logo_head.rcs_sticky-top #rcs_menu_header_top ul h6 a span {
  padding-bottom: 28px;
}

/*fixed header css for menu*/
/*Menu changes css new*/
.rcs_custom_ring_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  background-color: #fbfbfb;
  border-radius: 12px;
  border: 2px solid #f6f6f6;
  height: 113px;
}

.rcs_custom_ring_sec1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rcs_custom_ring_sec img {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.rcs_custom_ring_sec span {
  border: 1px dashed #d6d6d6;
  margin: 0px 10px;
  height: 70px;
}

.rcs_custom_ring_sec h5 {
  font-size: 14px;
  padding: 0 0 0 15px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
}

.rcs_custom_ring_setting {
  display: block;
  padding: 18px 10px;
  background-color: #fbfbfb;
  border-radius: 12px;
  border: 2px solid #f6f6f6;
}

.rcs_custom_ring_setting img {
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: 0 auto 10px;
  display: block;
}

.rcs_custom_ring_setting h5 {
  font-size: 14px;
  padding: 0px;
  height: 30px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
}

/*Menu changes css new*/
@media (min-width: 1025px) and (max-width: 1199px) {
  #rcs_menu_header_top ul li {
    padding: 10px 0 0;
  }

  #rcs_menu_header_top ul li h6::after {
    bottom: -0.5px;
    height: 2px !important;
  }



  .rcs_right_side ul li {
    padding: 0 0px !important;
    z-index: 999;
  }



  .rcs_logo_head.rcs_sticky-top .rcs_megaMenu {
    /* height: 100vh; */
  }

  #rcs_menu_header_top ul h6 a span:after {
    top: 28px;
  }

  .rcs_diamonds_content {
    padding: 30px 10px 0;
  }

  .rcs_diamonds_ring_inner2 h4 {
    font-size: 12px;
    line-height: 20px;
    bottom: 17px;
    background: black;
    position: relative;
    top: -13px;
    height: 75px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .rcs_diamonds_ring_inner h4 {
    font-size: 13px;
    line-height: 20px;
    bottom: 17px;
  }

  .rcs_right_bg {
    background: #fbfbfb;
  }

  .rcs_diamonds_ring_inner2 img {
    max-width: 150px;
  }

  .rcs_diamonds_ring_inner img {
    /* max-width: 195px; */
    max-width: 100%;
    margin-bottom: 13px;
  }

  .rcs_diamonds_content1 {
    height: 324px;
  }

  .rcs_diamonds_content1_inner {
    bottom: 80px;
  }

  .rcs_diamonds_content p {
    font-size: 12px;
    font-weight: 500;
  }

  .rcs_diamonds_content h2 {
    font-size: 15px;
  }

  .rcs_gemstone a {
    margin: 1px 0px;
  }

  .rcs_gemstone_content1 {
    height: 440px;
  }

  .rcs_engagRing_content1_inner p {
    font-size: 12px;
  }

  .rcs_engagRing_content1 {
    height: 450px;
  }

  .rcs_engagRing_content1_inner {
    left: 10px;
  }

  .rcs_engagRing_content1_inner button {
    padding: 10px !important;
  }

  .rcs_engagement {
    padding: 30px 0 0 !important;
  }

  .rcs_engagement a {
    margin: 2px 0px;
  }

  .rcs_weddingRing_content1 {
    height: 451px;
    background-size: contain;
    background-position: bottom;
  }

  .rcs_weddingRing_content1_inner {
    top: 5%;
  }

  .rcs_fineJewelry_content1 {
    height: 451px;
  }

  .rcs_fine_img {
    top: 230px;
    right: 60px;
  }

  .rcs_diamonds_content ul li {
    width: 100%;
    height: auto;
    margin: 3px !important;
    padding: 3px !important;
  }

  .rcs_fine_img img {
    width: 250px;
  }

  .cust_padd_diamonds_ring2 {
    padding: 0 1px;
  }

  /*Menu changes css new*/
  .rcs_custom_ring_sec {
    padding: 10px 0px;
    height: 93px;
    margin-bottom: 10px;
  }

  .rcs_custom_ring_sec h5 {
    font-size: 14px;
  }

  .rcs_custom_ring_sec.rcs_custom_ring_sec3.active {
    padding: 15px 20px;
    height: auto;
  }

  .rcs_custom_ring_sec.rcs_custom_ring_sec3.active span {
    display: none;
  }

  .rcs_custom_ring_sec.rcs_custom_ring_sec3.active img {
    margin: 0 auto 10px;
    display: block;
  }

  .rcs_custom_ring_setting h5 {
    font-size: 12px;
    height: 30px;
  }

  /*Menu changes css new*/
}

@media (min-width: 1471px) {
  .rcs_fine_img {
    width: 60%;
  }
  .rcs_weddingRing_content1 {
    background-position: top right;
    background-size: 100%;
  }
}

@media (min-width: 1023px) and (max-width: 1470px) {
  #rcs_menu_header_top ul li {
    padding: 0px 0 0;
  }

  .rcs_right_side.dfl_right_icn_content .btn {
    padding: 0;
    font-size: 14px;
  }

  .rcs_search_bar.dfl_top_search {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
  }

  #rcs_menu_header_top ul li h6::after {
    bottom: -0.5px;
    height: 2px !important;
  }

 

  .rcs_right_side ul li {
    padding: 0 0px !important;
    z-index: 999;
  }

 

  #rcs_header_seaction {
    background: #e2e9e9;
  }
  .rcs_logo_head {
    background: #e2e9e9;
  }

  .rcs_diamonds_content_inner a {
    margin: 0 !important;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top
    #rcs_menu_header_top
    ul.rcs_header_menu {
    justify-content: right;
  }

  .rcs_logo_head.rcs_sticky-top .rcs_megaMenu {
    /* height: 100vh; */
  }

  #rcs_menu_header_top ul h6 a span:after {
    top: 26px;
  }

  .rcs_diamonds_content {
    padding: 30px 10px 0;
  }

  .rcs_diamonds_ring_inner2 h4 {
    font-size: 12px;
    line-height: 20px;
    bottom: 17px;
    background: black;
    position: relative;
    top: -13px;
    height: 75px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .rcs_diamonds_ring_inner h4 {
    font-size: 13px;
    line-height: 20px;
    bottom: 17px;
  }

  .rcs_right_bg {
    background: #fbfbfb;
  }

  .rcs_diamonds_ring_inner2 img {
    max-width: 150px;
  }

  .rcs_diamonds_ring_inner img {
    /* max-width: 195px; */
    max-width: 100%;
    margin-bottom: 13px;
  }

  .rcs_diamonds_content1 {
    height: 324px;
  }

  .rcs_diamonds_content1_inner {
    bottom: 30px;
  }

  .rcs_diamonds_content p {
    font-size: 10px;
    font-weight: 500;
  }

  .rcs_diamonds_content h2 {
    font-size: 15px;
  }

  .rcs_gemstone a {
    margin: 1px 0px;
  }

  .rcs_gemstone_content1 {
    height: 440px;
  }

  .rcs_engagRing_content1_inner p {
    font-size: 12px;
  }

  .rcs_engagRing_content1 {
    height: 450px;
  }

  .rcs_engagRing_content1_inner {
    left: 10px;
  }

  .rcs_engagRing_content1_inner button {
    padding: 10px !important;
  }

  .rcs_engagement {
    padding: 30px 0 0 !important;
  }

  .rcs_engagement a {
    margin: 2px 0px;
  }

  .rcs_weddingRing_content1 {
    height: 451px;
    background-size: contain;
    background-position: bottom;
  }

  .rcs_weddingRing_content1_inner {
    top: 5%;
  }

  .rcs_fineJewelry_content1 {
    height: 451px;
  }

  .rcs_fine_img {
    top: 230px;
    right: 60px;
  }

  .rcs_fine_img img {
    width: 250px;
  }

  .cust_padd_diamonds_ring2 {
    padding: 0 1px;
  }

  /*Menu changes css new*/
  .rcs_custom_ring_sec {
    padding: 5px 10px;
    height: 80px;
    margin: 5px;
  }

  .rcs_custom_ring_sec span {
    border: 1px dashed #d6d6d6;
    height: 50px;
  }

  .rcs_custom_ring_sec h5 {
    font-size: 12px;
  }

  .rcs_custom_ring_sec.rcs_custom_ring_sec3.active {
    padding: 15px 20px;
    height: 70px;
  }

  .rcs_custom_ring_sec.rcs_custom_ring_sec3.active span {
    height: 50px;
  }

  .rcs_custom_ring_sec img,
  .rcs_custom_ring_setting img {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .rcs_custom_ring_setting h5 {
    font-size: 11px;
    height: 10px;
    padding: 0;
  }

  .mega-list-cat ul li a {
    font-size: 12px !important;
  }

  img.img-size {
    width: 25px !important;
  }

  /*Menu changes css new*/
}

.dfl_tab_search {
  display: none !important;
}

/*Responsive css*/
@media (max-width: 1023px) {
  .dfl_tab_search {
    display: block !important;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_right_side ul li {
    color: #a9a9a9;
  }
  .rcs_right_side.dfl_right_icn_content ul li span.dfl_right_menu_text {
    display: none;
  }
}

@media (min-width: 1025px) {
  .rcs_right_side.dfl_right_icn_content.dfl_mobile_icn {
    display: none;
  }
}

@media (min-width: 1023px) and (max-width: 1199px) {
  .rcs_diamonds_content_fine ul li {
    width: 105px !important;
  }
  .rcs_diamonds_content_fine ul {
    grid-template-columns: auto auto;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .rcs_menu {
    display: none;
  }

  .rcs_logo_section img {
    width: 160px;
    /* margin: 10px auto; */
    margin: 3px 0 10px 40px;
    display: block;
    padding: 0;
  }

  .rcs_right_side_mobile1 {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .rcs_left_side ul li a span,
  .rcs_left_side ul li span {
    display: none;
  }

  .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root,
  .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root:focus {
    width: 100% !important;
  }

  .rcs_right_side ul li .MuiInputBase-input {
    width: 100% !important;
  }

  .rcs_right_side_mobile ul li {
    width: 100%;
  }

  .rcs_left_side ul li {
    display: none;
  }

  

  .rcs_top_head_content p {
    font-size: 11px;
  }

  .rcs_mobile_header_menu img {
    width: 27px;
  }

  .rcs_extra_link a {
    padding: 20px 14px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.2;
    width: 100%;
    display: block;
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .rcs_mobile_menu .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .rcs_mobile_menu .MuiPaper-elevation1 {
    box-shadow: none;
    width: 100%;
  }

  .rcs_mobile_menu .MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
  }

  .rcs_mobile_menu .MuiAccordionSummary-content.Mui-expanded {
    margin: 20px 0;
  }

  .rcs_mobile_inner_menu {
    padding: 0 !important;
  }

  .rcs_mobile_inner_menu .MuiIconButton-edgeEnd {
    margin-right: -11px !important;
  }

  .rcs_mobile_inner_menu .MuiAccordionSummary-root.Mui-expanded {
    border-top: none !important;
    min-height: 50px !important;
  }

  .rcs_mobile_inner_menu .MuiAccordion-root.Mui-expanded {
    margin: 1px 0 !important;
    width: 100%;
  }

  #loginbutton {
    padding: 0 !important;
  }

  .rcs_right_side ul li svg {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  .gs_loader1 .MuiCircularProgress-root {
    position: absolute;
    top: 43%;
    left: 43%;
  }

  .rcs_mobile_menu .MuiTypography-body1 {
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
  }

  .MuiAccordionDetails-root.rcs_mobile_inner_menu1,
  .MuiAccordionDetails-root.rcs_mobile_inner_menu1 p {
    font-size: 13px !important;
    text-transform: capitalize;
    padding-left: 10px !important;
  }

  .rcs_account_content_left {
    padding: 10px;
  }

  .rcs_register_content .col-md-4.col-sm-6.col-12 {
    padding: 0;
  }

  .alert.alert-info.text-left span {
    font-size: 12px;
  }

  .rcs_custom_padding {
    padding: 0 5px !important;
  }

  .rcs_philan_content .slick-prev,
  .rcs_philan_content .slick-next {
    display: none !important;
  }

  .rcs_philan_content .rcs_brand_item img {
    margin: 0 auto;
  }

  .rcs_menu_list_item ul li {
    display: block;
    padding: 5px 10px;
    font-size: 14px;
    min-height: 30px;
  }

  .rcs_search_bar input {
    width: 100%;
    margin-left: 0px;
  }

  /* .rcs_search_bar svg {
    position: absolute;
    right: 10px;
    top: 7px;
  } */

  button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
    min-height: 25px;
    line-height: 25px;
    min-width: 25px;
}

  .rcs_mobile_menu {
    width: 280px;
  }

  .rcs_mobile_inner_menu1 p,
  .rcs_mobile_inner_menu p {
    font-size: 13px !important;
  }

  .rcs_mobile_inner_menu .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .rcs_mobile_menu .MuiIconButton-root {
    padding: 4px 12px !important;
  }

  .rcs_mobile_menu .MuiAccordionSummary-content {
    border-right: 1px solid #ccc;
    margin: 20px 0;
  }

  .rcs_mobile_menu .MuiAccordionDetails-root {
    display: block;
    margin-bottom: 5px;
  }

  .rcs_mobile_inner_menu .MuiAccordionSummary-root {
    min-height: 28px !important;
  }
}

@media (max-width: 767px) {
  .banner-thumb:after {
    content: '';
    background: #00000025;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}
  .rightfooter.dfl_footer_links {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapperTestimonial::before {
    display: none;
  }
  .rcs_testimonials h5 {
    margin-bottom: 30px !important;
  }
  body {
    overflow-x: hidden;
  }
  .dfl_header_logo {
    /* margin-top: 6px; */
  }

  span.dfl_right_menu_text {
    display: none;
  }
  .rcs_after_login_button {
    min-width: 40px;
  }
  .rcs_header {
    padding: 5px 0px;
    padding-top: 10px;
  }

  .rcs_search_bar input {
    padding-left: 35px;
    height: 40px;
  }

  .rcs_top_header .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .rcs_header {
    padding: 5px 0px;
    padding-top: 0px;
  }

  .rcs_search_bar svg:last-child {
    font-size: 20px;
  }

  .rcs_mobile_header_menu {
    top: 10px;
    z-index: 999;
  }

  img.dfl_logo_top_m {
    margin-left: 30px;
    width: 150px;
    position: relative;
    top: 0px;
    left: 5px;
  }

  .rcs_right_side.dfl_right_icn_content ul li {
    display: flex;
    align-items: center;
    margin-right: -4px;
  }

  .rcs_right_side.dfl_right_icn_content {
    /* position: absolute; */
    /* right: 2px; */
    /* top: -36px; */
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top
    .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    right: 12px;
    top: 1px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_header {
    padding: 10px 0px;
    padding-top: 7px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_logo_top {
    display: block;
    padding-bottom: 0px;
    margin-left: 2.5rem;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .m-auto.rcs_mobile_header_menu {
    left: 10px;
    top: 3px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo {
    width: 150px;
    position: relative;
    top: 2px;
    left: -12px;
  }
}

@media (min-width: 320px) and (max-width: 335px) {
  .rcs_logo_head.rcs_sticky-top.sticky-top
    .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    right: 2px;
    top: 6px;
  }
  .dfl_tab_search {
    display: none !important;
  }
  /* .dfl_tab_search {
        display: none !important;
    } */
  .rcs_logo_section img {
    width: 140px;
    margin: 4px 0 4px 40px;
    padding: 0;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo {
    width: 150px;
    position: relative;
    top: 1px;
    left: -10px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top
    .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .m-auto.rcs_mobile_header_menu {
    left: 10px;
    top: 3px;
  }

  /* .rcs_mobile_header_menu {
        display: block;
        position: absolute;
        top: 1px;
        cursor: pointer;
        left: 0;
    } */
  .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    right: 0;
    top: -16px;
  }

  .rcs_right_side ul li {
    padding: 0 1px !important;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .rcs_currency {
    display: none;
  }

  .rcs_top_head_content p {
    padding: 5px;
  }

  /*top header css new*/
  .rcs_top_head_content_right {
    display: none;
  }

  .rcs_right_side ul li {
    padding: 0 3px;
  }

  .rcs_right_side li:nth-child(5) {
    margin: 0;
  }

  .rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 9;
    top: 85px;
    left: 0;
  }

  .rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 9;
    top: 50px;
    left: 0;
  }

  span.rcs_cart_badge,
  span.rcs_wish_badge {
    top: -12px;
  }

  /*top header css new*/
}

@media (min-width: 576px) and (max-width: 767px) {
  .alert.alert-info.text-left {
    align-items: center;
  }

  .rcs_register_content .col-md-4.col-sm-8.col-12 {
    padding: 0;
  }

  .rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 90px;
    z-index: 9;
    top: 76px;
  }

  .rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 90px;
    z-index: 9;
    top: 40px;
  }

  .rcs_top_head_content_right {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rcs_search_bar input {
    padding-left: 40px;
  }

  .dfl_header_fixed_logo {
    margin-left: 52px;
  }

  .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    /* top: -60px; */
    right: -5px;
    bottom: 0;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top
    .rcs_right_side.dfl_right_icn_content {
    top:13px
  }

  .rcs_mobile_header_menu img {
    width: 35px;
  }

  .rcs_mobile_header_menu {
    /* top: 22px; */
    /* left: 0; */
  }

  .rcs_search_bar.dfl_top_search {
    width: 100%;
    margin: auto;
    margin-bottom: 7px;
    margin-top: 10px;
  }

  .rcs_right_side.dfl_right_icn_content ul li {
    margin-right: 5px;
  }

  .dfl_header_logo_top.dfl_mob {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_mobile_header_menu {
    display: block;
    position: absolute;
    z-index: 999;
    left: 15px;
    cursor: pointer;
    margin: auto 0 !important;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_header {
    padding: 10px 0px;
  }

  .dfl_logo_top_m {
    margin-left: 40px;
    position: relative;
    top: -3px;
    height: 50px;
  }
  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo img {
    height: 50px;
    min-width: auto;
  }

  /**************************/
  .rcs_top_head_content p {
    padding: 0px;
  }

  .rcs_newcontainer {
    width: 100%;
    padding: 0 !important;
  }

  .rcs_custom_padding.col-sm-6.col-12 {
    padding: 0 5px !important;
  }

  .rcs_logo_section img {
    width: 160px;
    margin: 2px auto 10px;
    display: block;
    padding: 0;
    margin-top: 2px;
  }

  span.rcs_cart_badge,
  span.rcs_wish_badge {
    top: -12px;
  }

  .rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 120px;
    z-index: 9;
    top: 40px;
  }

  .rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 120px;
    z-index: 9;
    top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .rcs_logo_head.rcs_sticky-top.sticky-top
    .rcs_right_side.dfl_right_icn_content {
    position: absolute;
    top: 38px;
    right: 30px;
    bottom: 0;
  }

  img.dfl_logo_top_m {
    margin-left: 40px;
  }

  .rcs_header .col-md-4.col-sm-12 {
    display: none;
  }

  .rcs_top_head_content p {
    padding: 10px;
  }

  .rcs_header .col-lg-4.col-md-12.col-sm-12.col-12 {
    max-width: 100%;
    flex: 100%;
  }

  .rcs_right_side.rcs_right_side_mobile1.d-lg-none {
    display: block !important;
  }

  .rcs_right_side_mobile ul li.d-none.d-lg-block {
    display: none !important;
  }

  .rcs_menu {
    display: none;
  }

  .rcs_logo_section img {
    width: 160px;
    margin: 2px auto 10px;
    display: block;
    padding: 0;
  }

  .rcs_right_side_mobile1 {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .rcs_left_side ul li a span,
  .rcs_left_side ul li span {
    display: none;
  }

  .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root,
  .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root:focus {
    width: 100% !important;
  }

  .rcs_right_side ul li .MuiInputBase-input {
    width: 100% !important;
  }

  .rcs_right_side_mobile ul li {
    width: 100%;
  }

  .rcs_left_side ul li {
    display: none;
  }

  .rcs_mobile_header_menu {
    display: block;
    position: absolute;
    top: 20px;
    z-index: 999;
    cursor: pointer;
    left: 0;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_mobile_header_menu {
    left: inherit;
  }

  .rcs_top_head_content p {
    font-size: 11px;
    padding: 0;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo {
    padding-top: 10px;
    left: 10px;
    margin-left: 55px;
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_mobile_header_menu {
    left: 15px;
    top: 30px;
    bottom: 0;
  }

  .rcs_mobile_header_menu img {
    width: 40px;
  }

  .rcs_extra_link a {
    padding: 17px 14px;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.2;
    width: 100%;
    display: block;
  }

  /* .rcs_logo_head.rcs_sticky-top.sticky-top .dfl_header_fixed_logo {
        padding-top: 10px;
        left: 10px;
        margin-left: 60px;
    } */

  .rcs_mobile_menu .MuiAccordion-root.Mui-expanded {
    margin: 1px 0;
  }

  .rcs_mobile_menu .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .rcs_mobile_menu .MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
  }

  .rcs_mobile_menu .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
  }

  .rcs_mobile_inner_menu {
    padding: 0 !important;
  }

  .rcs_mobile_inner_menu .MuiIconButton-edgeEnd {
    margin-right: -37px !important;
  }

  .rcs_mobile_inner_menu .MuiAccordionSummary-root.Mui-expanded {
    border-top: none !important;
    min-height: 50px !important;
  }

  .rcs_mobile_inner_menu .MuiAccordion-root.Mui-expanded {
    margin: 1px 0 !important;
  }

  #loginbutton {
    padding: 0 !important;
    margin-top: -4px !important;
  }

  .gs_loader1 .MuiCircularProgress-root {
    position: absolute;
    top: 43%;
    left: 43%;
  }

  .rcs_mobile_menu .MuiTypography-body1 {
    cursor: pointer;
  }

  .rcs_account_content_left {
    padding: 10px;
  }

  .rcs_register_content .col-md-4.col-sm-6.col-12 {
    padding: 0;
  }

  .alert.alert-info.text-left span {
    font-size: 12px;
  }

  .rcs_custom_padding {
    padding: 0 !important;
  }

  .rcs_search_bar input {
    width: 100%;
    margin-left: 0px;
  }

  button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
    border-radius: 50% !important;
    height: 25px;
    width: 25px;
    line-height: 20px;
    padding: 0;
  }

  .rcs_large_Tab {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .rcs_large_Tab1 {
    display: none;
  }

  .rcs_mobile_menu .MuiAccordionSummary-content {
    border-right: 1px solid #ccc;
  }

  .rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 120px;
    z-index: 9;
    top: 40px;
  }

  .rcs_search_bar input {
    padding-left: 40px;
  }

  .rcs_right_side li:nth-child(1) img {
    position: relative;
    top: -2px;
  }

  .rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 300px;
    right: 120px;
    z-index: 9;
    top: 30px;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .rcs_right_side_mobile1 ul li a {
    font-size: 12px;
    padding: 0 10px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
  }

  span.dfl_right_menu_text {
    color: #a9a9a9;
  }

  .rcs_right_side_mobile1 ul li a img {
    width: 16px;
    margin-right: 5px;
  }

  .rcs_right_side_mobile1 ul li .dropdown-menu {
    z-index: 9999;
    min-width: 6rem !important;
  }

  .rcs_mobile_currency .rcs_dropdown_lang a {
    font-size: 12px;
    padding: 0 10px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
  }

  .rcs_mobile_currency .rcs_dropdown_lang a img {
    width: 16px;
    margin-right: 5px;
  }

  .rcs_mobile_currency .rcs_dropdown_lang .dropdown-menu {
    z-index: 9999;
    min-width: 6rem !important;
  }

  .rcs_mobile_currency {
    padding: 10px 20px;
    width: 100%;
    /* display: block ruby; */
    display: flow-root;
    border-bottom: 1px solid #e5e5e5;
  }

  .rcs_mobile_currency .rcs_dropdown_lang {
    float: right;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #rcs_menu_header_top ul ul {
    top: 42px !important;
  }

  .rcs_right_side ul li {
    padding: 0 0px !important;
    z-index: 999;
  }

  .rcs_before_login.btn.btn-link {
    padding: 0.375rem 0.15rem;
  }

  .rcs_before_login.btn.btn-link {
    padding: 0.375rem 0.05rem;
  }

  .rcs_logo_section img {
    width: 300px;
  }

  #rcs_menu_header_top ul span a,
  #rcs_menu_header_top ul h6 a {
    font-size: 11px;
    padding: 0 5px;
  }

  .rcs_left_side ul li a {
    font-size: 8px;
  }

  #rcs_menu_header_top ul ul li {
    width: auto;
  }

  .rcs_testimonial_inner .slick-next,
  .rcs_testimonial_inner .slick-prev,
  .rcs_philan_content .slick-next,
  .rcs_philan_content .slick-prev {
    display: none !important;
  }

  .rcs_newcontainer {
    width: 100%;
    padding: 0;
  }

  .rcs_main_btn2 {
    width: 90%;
    padding: 0 0.25rem;
  }

  .rcs_collection h3 {
    font-size: 24px;
  }

  .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
    font-size: 12px;
    width: 91px;
    padding: 0;
  }

  .rcs_search_bar svg {
    position: absolute;
    right: 10px;
    top: 7px;
  }

 
}

@media (min-width: 1300px) and (max-width: 1366px) {
  .rcs_top_head_content_right {
    padding-top: 4px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
 
  .rcs_right_side ul {
    margin-top: 5px;
  }
  .dfl_header_logo img {
    /* width: 75%; */
  }
  .rcs_logo_head.rcs_sticky-top.sticky-top img {
    /* max-width: 42%; */
  }
  .rcs_top_head_content ul li {
    color: white;
  }
  .rcs_logo_head.rcs_sticky-top.sticky-top
    #rcs_menu_header_top
    ul
    h6
    a
    span:after {
    top: 34px;
  }
  .rcs_top_head_content_right ul li:first-child {
    border-right: 0px solid rgb(255, 255, 255);
    padding: 0 10px;
  }

  .rcs_top_head_content span {
    color: #636363;
    font-weight: 400;
  }
  button.rcs_blank_button.rcs_after_login_button.btn.btn-primary .rcs_mobile{
    color: #fff !important;
  }
  #rcs_menu_header_top ul span a,
  #rcs_menu_header_top ul h6 a,
  #rcs_menu_header_top ul h6.rcs_search {
    font-size: 12px;
    padding: 0 7px 11px;
  }

  .rcs_shop_by_diamond_title {
    margin-bottom: 2rem;
  }

  #rcs_menu_header_top ul ul li {
    width: auto;
  }

  #rcs_menu_header_top ul li:nth-child(5) ul li {
    width: 110px;
  }

  #rcs_menu_header_top {
    width: 100%;
    margin: 0 auto;
  }

  .slick-slider.rcs_slider_img .slick-next {
    right: 50px;
  }

  .slick-slider.rcs_slider_img .slick-prev {
    left: 50px;
  }

  .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
    font-size: 12px;
  }
}

@media (min-width: 1023px) and (max-width: 1340px) {
  .rcs_logo_section img {
    width: 150px;
    margin: 0px auto 0px;
  }

  #rcs_menu_header_top ul h6 a {
    /* height: 30px; */
    /* line-height: 30px !important; */
}

  .rcs_top_head_content li {
    color: var(--primary);
    font-size: 10px;
  }

  #rcs_menu_header_top ul li {
    padding: 0px 0 0;
  }

  .rcs_right_side ul li {
    padding: 0 4px;
    z-index: 999;
  }

  .rcs_right_side ul li svg {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 25%;
    right: 230px;
    z-index: 9;
    top: 50px;
  }

  .rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 25%;
    right: 230px;
    z-index: 9;
    top: 20px;
  }

  button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
    height: 20px;
    width: 20px;
  }

  #rcs_menu_header_top ul span a,
  #rcs_menu_header_top ul h6 a,
  #rcs_menu_header_top ul h6.rcs_search {
    font-size: 12px;
    padding: 0 10px;
  }

  .rcs_right_side ul {
    margin-top: 5px;
  }

  .rcs_header {
    padding: 0px 0 0;
  }

  .mask-overly {
    top: 81px;
  }

  .rcs_logo_head.rcs_sticky-top .mask-overly {
    /* top: 50px; */
  }

  #rcs_menu_header_top ul ul {
    /* top: 40px; */
  }

  #rcs_menu_header_top ul li h6::after {
    bottom: -0.5px;
    height: 2px !important;
  }

  #rcs_menu_header_top ul ul span a {
    padding: 7px 10px;
    font-size: 10px;
  }

  .rcs_right_side_mobile ul li .dropdown-menu {
    transform: translate3d(5px, 23px, 0px) !important;
  }

  .rcs_top_head_content p {
    font-size: 10px;
    padding: 2px 0 0;
  }

  .rcs_top_head_content_right ul li:first-child {
    border-right: 0px solid rgb(255, 255, 255);
    padding: 0 10px;
  }

  .rcs_top_head_content_right ul li {
    font-size: 10px;
  }

  .rcs_top_head_content_right ul li svg {
    width: 12px;
    height: 12px;
  }

  .rcs_top_head_content_right ul li span {
    padding: 0 5px;
  }

  span.rcs_cart_badge,
  span.rcs_wish_badge {
    height: 15px;
    width: 15px;
    line-height: 15px;
    font-size: 10px;
    top: -1px;
  }
}
/*mega menu*/
.megaMenuTheme {
  list-style: none;
  display: flex;
}
.megaMenuTheme > .parentItem {
  margin-right: 20px;
}
.megamenu {
  position: absolute;
  left: 0px;
  float: left;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  display: none;
  z-index: 3;
  background: #fff;
}
.parentItem:hover > .megamenu {
  display: block;
  animation: 0.3s slide-up;
}

@keyframes slide-up {
  from {
    margin-top: 20px;
  }
  to {
    margin-top: 0px;
  }
}
.parentItem {
  text-transform: uppercase;
  color: #000;
}
.parentItem a {
  font-size: 13px;
  color: #000;
  font-weight: 700;
}
.rcs_top_header,
body {
}
/*mega menu*/
/* @media (min-width: 1200px) and (max-width: 1340px){ 
        .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
            font-size: 12px;
            position: absolute;
            left: -74px;
            top: -12px;
            padding: 0;
            width: 91px;
        }
    } */
/*Responsive css*/

.rcs_megaMenu {
  background-color: rgba(0, 0, 0, 0.384);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.rcs_megaMenu_inner {
  height: 100%;
  /* background-color: #e2e9e9; */
  border-top: 1px solid #7a767647;
  overflow: hidden;
  padding: 0 0 20px;
}

.rcs_diamonds_content {
  padding: 20px 20px 0;
  position: relative;
}

.rcs_diamonds_content h2 {
  color: #000;
  font-size: 14px;
  font-weight: 700 !important;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  padding: 0 5px;
  text-transform: uppercase;
}

.cust_padd_diamonds_shape {
  padding: 0 5px;
}

.navReplacement,
.rcs_diamonds_content_inner a {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 3px 0;
}

.rcs_diamonds_content_inner img {
  float: left;
  line-height: 30px;
  margin: 0 5px 0 0;
  width: 30px;
}

.mega-list-cat ul li a:hover {
  color: #000 !important;
}

.mega-list-cat ul li a {
  color: inherit;
  color: #505050 !important;
  display: block;
  font-size: 15px;
  padding: 5px;
}
.mega-list-cat ul li a {
  font-size: 14px;
}

.rcs_diamonds_content ul {
  margin: 0;
  padding: 0;
}

img.img-size {
  margin-right: 5px;
  margin-top: -5px;
  width: 30px;
}

.rcs_logo_head {
  position: relative;
}

.dfl_menu_border {
  border-right: 1px solid #7a767647;
  height: 260px;
  position: absolute;
  right: 2%;
  top: 0;
}

.bw-Shop-by-metal a img {
  width: 22px;
}

.rcs_top_header_menu_dropdown.mega_menu_content_main.visible.bw-Engagement-Rings
  .bw-Shop-by-style
  .rcs_diamonds_content_inner
  a
  img {
  width: 45px !important;
  height: auto;
}

.hover_overlayer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.384);
  width: 100%;
  height: calc(100% - 127px);
  left: 0;
  z-index: 9;
}
