/*Ring Setting page css*/
.erd-steps {
  box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
}
.steps-1-2-3-block > .step {
  background-color: #f8f8f8;
  border: 1px solid #ebebeb;
}
.steps-1-2-3-block.active > .step {
  background: white;
}
.steps-1-2-3-block .step .title > a, .steps-1-2-3-block .step .title > span {
  color: #2c2c2c;
}
.text-default {
  color: #b0b0b0 !important;
}
.steps-1-2-3-block .step .rhombus, .steps-1-2-3-block .step .rhombus-top {
  border-bottom: 20px solid #ebebeb;
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block .step .rhombus::before, .steps-1-2-3-block .step .rhombus-top::before {
  border-top: 20px solid #ebebeb;
  border-bottom: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block.active .step .rhombus-top {
  border-bottom: 20px solid white;
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block.active .step .rhombus-top::before {
  border-top: 20px solid white;
  border-bottom: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block .step .rhombus-top {
  border-bottom: 20px solid #f8f8f8;
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block .step .rhombus-top::before {
  border-top: 20px solid #f8f8f8;
  border-bottom: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.steps-1-2-3-block:last-child > .step {
  border-right: 1px solid #ebebeb;
}
.rcs_filter_accordion_sec > div {
  background-color: initial!important;
  box-shadow: none!important;
}
/* Ring Setting page css end */

.widget {
  position: relative;
  width: 100%;
}

.pr-top-minus10 {
  position: relative;
  top: -24px;
}

.pr-top-10 {
  position: relative;
  top: 24px;
}

.steps-1-2-3-block:first-child {
  width: 95%;
  margin-left: 0;
}

.steps-1-2-3-block {
  display: block;
  width: 100%;
  padding: 0;
  position: relative;
  margin-left: -11px;
}

.steps-1-2-3-block>.step {
  display: block;
  width: calc(100% - 11px);
  line-height: 1;
  font-weight: 600;
  border-right: 0;
  height: 40px;
  border-radius: 0;
  position: relative;
}

.steps-1-2-3-block:first-child>.step {
  border-radius: 3px 0 0 3px;
}

.rcs_diamond_content h1 {
  text-transform: uppercase;
}

.steps-1-2-3-block .step .step-number {
  font-size: 0px;
  padding: 0 0 0 25px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.steps-1-2-3-block:first-child .step .step-number {
  padding-left: 10px;
}

.steps-1-2-3-block:first-child .step .title {
  padding: 0 0.2rem 0 0;
  z-index: 10;
}

.steps-1-2-3-block .step .title {
  font-size: 12px;
  padding: 0 0.75rem 0 0;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.steps-1-2-3-block.active .step .title {
  padding: 0;
}

.steps-1-2-3-block:first-child .step .title {
  padding: 0 0.2rem 0 0;
  z-index: 10;
}

.steps-1-2-3-block .step .title strong {
  font-size: 12px;
}

.steps-1-2-3-block .step .title>a:not(.disabled) {
  text-decoration: underline !important;
}

.steps-1-2-3-block .step .title>a {
  font-size: 10px;
}

.steps-1-2-3-block .step .title>a,
.steps-1-2-3-block .step .title>span {
  font-size: 10px;
  font-weight: normal;
  text-transform: none;
  padding-right: 1.25rem;
}

.steps-1-2-3-block .step .image {
  display: none;
}

.steps-1-2-3-block .step .image i.erd-empty-ring {
  background: transparent url(https://v6master-mizuno.shopfinejewelry.com/images/erd-empty-ring.svg) center center no-repeat;
}

.steps-1-2-3-block .step .image i,
.steps-1-2-3-block .step .image img {
  display: block;
  width: 55px;
  height: 55px;
}

.steps-1-2-3-block .step .image i {
  margin: 0 10px;
}

.steps-1-2-3-block .step .rhombus,
.steps-1-2-3-block .step .rhombus-top {
  display: block;
  position: absolute;
  right: -11px;
  top: 0px;
  z-index: 1;
}

.steps-1-2-3-block .step .rhombus::before,
.steps-1-2-3-block .step .rhombus-top::before {
  display: block;
  position: absolute;
  content: "";
  top: 19px;
  left: -10px;
}

.steps-1-2-3-block.active .step .rhombus-top {
  display: block;
  position: absolute;
  right: -10px;
  z-index: 1;
}

.steps-1-2-3-block.active .step .rhombus-top::before {
  display: block;
  position: absolute;
  content: "";
  top: 19px;
  left: -10px;
  z-index: 2;
}

.steps-1-2-3-block .step .rhombus-top {
  display: block;
  position: absolute;
  right: -10px;
  z-index: 2;
}

.steps-1-2-3-block .step .rhombus-top::before {
  display: block;
  position: absolute;
  content: "";
  top: 19px;
  left: -10px;
  z-index: 2;
}

.steps-1-2-3-block .step .image i.erd-diamond {
  background: transparent url(https://v6master-mizuno.shopfinejewelry.com/images/erd-diamond.svg) center center no-repeat;
}

.steps-1-2-3-block .step .image i.erd-diamond-ring {
  background: transparent url(https://v6master-mizuno.shopfinejewelry.com/images/erd-diamond-ring.svg) center center no-repeat;
}

.steps-1-2-3-block:last-child .rhombus,
.steps-1-2-3-block:last-child .rhombus-top {
  display: none;
}

.steps-1-2-3-block:last-child>.step {
  width: 100%;
  padding-right: 10px;
}

.steps-1-2-3-block:last-child>.step {
  border-radius: 0 3px 3px 0;
}


/*! CSS Used fontfaces */
/* @font-face {
  font-family: "big-ring-settings";
  src: url("https://v6master-mizuno.shopfinejewelry.com/fonts/ring-settings/fonts/big-ring-settings.eot?xqhcic");
  src: url("https://v6master-mizuno.shopfinejewelry.com/fonts/ring-settings/fonts/big-ring-settings.eot?xqhcic#iefix") format("embedded-opentype"),
    url("https://v6master-mizuno.shopfinejewelry.com/fonts/ring-settings/fonts/big-ring-settings.ttf?xqhcic") format("truetype"),
    url("https://v6master-mizuno.shopfinejewelry.com/fonts/ring-settings/fonts/big-ring-settings.woff?xqhcic") format("woff"),
    url("https://v6master-mizuno.shopfinejewelry.com/fonts/ring-settings/fonts/big-ring-settings.svg?xqhcic#big-ring-settings") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

.big-metal-types-wrap.collapse.show,
.big-ring-settings-wrap.collapse.show {
  display: -ms-flexbox;
  display: flex;
}

.big-metal-types-wrap .big-metal-types,
.big-ring-settings-wrap .big-ring-settings {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: baseline;
}

/* .big-metal-types::after,
.big-metal-types::before,
.big-ring-settings::before,
.big-ring-settings::after {
  content: "";
  display: block;
  float: none;
  clear: both;
} */

.big-ring-settings>li {
  margin-top: 0rem;
  cursor: pointer;
}

.grid-topbar-elem-list li .rcs_ring_setting_style {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.grid-topbar-elem-list li i,
.grid-topbar-elem-list li i::before {
  font-size: 80px;
  line-height: 40px;
}

.grid-topbar-elem-list li span {
  /* text-transform: uppercase; */
  /* font-size: 12px; */
}

.big-ring-settings>li:hover>.rcs_ring_setting_style,
.big-metal-types>li:hover>.rcs_ring_setting_style {
  border-radius: 2px;
}

.big-ring-settings>li>.rcs_ring_setting_style,
.big-metal-types>li>.rcs_ring_setting_style {
  padding: 10px 0;
  margin: 5px 0;
  font-size: 12px;
  cursor: pointer;
  position: relative;
}

.rcs_ring_setting_style.rcs_ring_setting_style_active {
  border-radius: 2px;
}

/*Shapes css*/
/*Metal css*/
.metal-type-circles {
  text-align: center;
  width: 100%;
  display: block;
}

.metal-type-circles.all-types {
  /* height: 40px; */
}

.metal-type-circles>i {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.metal-type-circles.all-types>i {
  width: 15px;
  height: 15px;
  margin-bottom: 1px;
  display: block;
}

.metal-type-circles.all-types>i+i {
  display: inline-block;
  margin: 0 2px;
}

/*Metal css*/
/*Price range slider css*/
ul.rcs_price_range_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 5px 5px 7px;
}

ul.rcs_price_range_input.rcs_prince_input_diamond.rcs_prince_input_diamond1 {
  margin: 5px 5px 0 18px;
}

ul.rcs_price_range_input li {
  width: 120px;
}

ul.rcs_price_range_input li input,
ul.rcs_price_range_input li input:focus {
  box-shadow: none;
  outline: none;
  background: transparent !important;
  border: 1px solid #00000012;
}

ul.rcs_price_range_input li span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.rcs_filter_accordion_sec {
  width: 100%;
}

.rcs_filter_accordion_sec .css-o4b71y-MuiAccordionSummary-content {
  flex-grow: unset !important;
}

/*Price range slider css*/
/*Diamond Shapes css*/
.rcs_diamond_table .MuiTableCell-head {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 14px;
}

.rcs_diamond_pagination .css-wjh20t-MuiPagination-ul {
  justify-content: center !important;
}

.rcs_diamond_pagination.diamond_p .css-nhb8h9 {
  justify-content: center !important;
}

.rcs_diamond_pagination .css-nhb8h9 {
  justify-content: end !important;
}

.rcs_diamond_pagination.diamond_p .css-nhb8h9 {
  justify-content: center !important;
}

li.longFilter--VqAB5.item--YBPgi.diamond-filter-dis {
  pointer-events: none;
  opacity: 0.5 !important;
}

.single-item-container--Xo5ic.gemstone_shape img {
  height: 70px !important;
  padding: 15px !important;
}

li.longFilter--VqAB5.item--YBPgi span img {
  width: 80px;
  height: 80px;
  line-height: 80px;
  padding: 10px;
  object-fit: contain;
}

ul.rcs_diamond_shapes_filter {
  display: flex;
  justify-content: space-around;
}

ul.rcs_diamond_shapes_filter li a {
  padding: 0.15rem 0;
  text-decoration: none;
  white-space: nowrap;
}

ul.rcs_diamond_shapes_filter li a i {
  font-size: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.rcs_filter_accordion_sec .MuiAccordionSummary-content.Mui-expanded,
.rcs_filter_accordion_sec .MuiAccordionSummary-content {
  /* justify-content: center; */
  display: contents;
}

.rcs_filter_accordion_sec .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 44px !important;
  background-color: var(--lightbg);
}

.rcs_filter_accordion_sec .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.rcs_mob_filter1.rcs_ring_setting_filter {
  /* padding: 13px 0;
  width: 100% !important; */
}

.rcs_mob_filter1.rcs_ring_setting_filter span {
  cursor: pointer;
}

.rcs_mob_filter1.rcs_ring_setting_filter1 {
  /* padding: 13px 0; */
}

.rcs_filter_accordion_sec .grid-topbar-elem-list li span.selectionIcon {
  font-size: 25px;
  margin: 0 auto;
}

.rc-slider.rc-slider-with-marks .rc-slider-dot {
  cursor: pointer !important;
}

/*Diamond Shapes css*/
/*Diamond page css*/
.rc-slider-dot {
  width: 4px !important;
  height: 7px !important;
  border-radius: 0 !important;
}

.rc-slider-handle {
  width: 12px !important;
  height: 12px !important;
  margin-top: -4px !important;
  box-shadow: 0px 1px 4px 0px #79797973;
}

.horizontalFilter .rc-slider-handle {
  width: 25px !important;
  height: 25px !important;
  margin-top: -11px !important;
  border-width: 2px !important;
}

.rcs_cut_slider .rc-slider-mark {
  left: -60px !important;
}

.rcs_fluorescence_slider .rc-slider-mark {
  left: -52px !important;
}

.rcs_color_slider .rc-slider-mark {
  left: -25px !important;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .rcs_cut_slider .rc-slider-mark {
    left: -40px !important;
  }

  .rcs_fluorescence_slider .rc-slider-mark {
    left: -33px !important;
  }

  .rcs_color_slider .rc-slider-mark {
    left: -16px !important;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  .rcs_cut_slider .rc-slider-mark {
    left: -45px !important;
  }

  .rcs_fluorescence_slider .rc-slider-mark {
    left: -40px !important;
  }

  .rcs_color_slider .rc-slider-mark {
    left: -16px !important;
  }
}

/* .rcs_diamond_filter_section h2 {
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 0 20px;
} */
.rcs_prince_input_diamond .input-group-text,
ul.rcs_price_range_input li span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: transparent;
  font-family: var(--inner_font);
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

ul.rcs_price_range_input li span::after {
  content: "";
  background: url("https://dl2vs6wk2ewna.cloudfront.net/showcase/images/vline.png");
  width: 1px;
  height: 32px;
  opacity: 0.5;
  display: inline-block;
  margin-left: 10px;
}

.rcs_prince_input_diamond .form-control,
.rcs_price_range_input .form-control {
  height: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.rcs_prince_input_diamond .input-group,
.rcs_price_range_input .input-group {
  flex-wrap: nowrap;
}

.table-align_filter--GVxiB {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.table-align_filter--GVxiB>* {
  text-align: center;
}

.action-area--soLSw {
  width: 100%;
}

.item--YBPgi {
  position: relative;
  width: 100%;
  border-right: none;
  transition: border-color 0.2s ease;
  cursor: pointer !important;
}

.item--YBPgi:last-child {
  /* border-top-right-radius: 4px; */
  /* border-bottom-right-radius: 4px; */
}

.item--YBPgi:first-child {
  /* border-top-left-radius: 4px; */
  /* border-bottom-left-radius: 4px; */
}

.item--YBPgi:hover {
  cursor: pointer;
  /* box-shadow:0 3px 6px rgba(0,0,0,.16); */
}

.item--YBPgi:hover .title--jqbwB {
  display: block;
  width: 100%;
}

.item--YBPgi.selected--Qs8si {
  border-radius: 4px;
  animation-name: selected_border--d7i7c;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  -webkit-animation-name: selected_border--d7i7c;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: selected_border--d7i7c;
  -moz-animation-duration: 0.2s;
  -moz-animation-timing-function: linear;
}

.item--YBPgi.selected--Qs8si+.item--YBPgi {
  border-left: none;
}

.filtered--yd5Rg {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 6px;
  width: 9px;
  height: 8px;
}

/* .title--jqbwB{
  display:none;
  font-size:13px;
  position:absolute;
  top:-21px;
  left:0;
  white-space:nowrap;
  text-transform: capitalize;
} */

.rcs.title--jqbwB {
  display: block;
  font-size: 13px;
  position: absolute;
  top: inherit;
  left: 0;
  white-space: nowrap;
  text-transform: capitalize;
  margin-top: 5px;
}

.icon--qAfox {
  font-size: 24px;
  padding: 9px;
  cursor: pointer;
  display: flex;
}

.single-item-container--Xo5ic {
  /* display: inline-flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 100%; */
}

.rcs_prince_input_diamond input::-webkit-outer-spin-button,
.rcs_prince_input_diamond input::-webkit-inner-spin-button,
.rcs_price_range_input input::-webkit-outer-spin-button,
.rcs_price_range_input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.rcs_prince_input_diamond input[type="number"],
.rcs_price_range_input input[type="number"] {
  font-family: var(--inner_font);
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.rcs_prince_input_diamond li,
ul.rcs_price_range_input li {
  width: 120px;
}

.rcs_prince_input_diamond li i,
ul.rcs_price_range_input li i {
  font-family: var(--inner_font);
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-style: normal;
}

.rcs_diamond_content h1 {
  text-align: center;
}

.rcs_diamond_content p {
  text-align: center;
  margin: 0;
}

.rcs_filter_accordion_sec .css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}

.rcs_filter_accordion_sec .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.rcs_diamonds_page_top_btn ul li button {
  padding: 10px 20px;
  width: 200px;
  margin: 20px 0 10px;
}

.rcs_diamonds_page_top_btn ul li:first-child button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rcs_diamonds_page_top_btn ul li:last-child button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rcs_diamonds_page_top_btn ul li button.active {
  background-color: #334e80;
  color: #fff;
}

/*Diamond page css*/
/*Shape wizard css*/
.rcs_shap-wizard_container.container {
  max-width: 1320px !important;
}

.rcs_shpae_padding {
  padding: 0;
}

.rcs_shape_wizard_step-1 img,
.rcs_shape_wizard_step-2 img,
.rcs_shape_wizard_step-3 img {
  width: 100%;
}

.rcs_shape_wizard_step-1,
.rcs_shape_wizard_step-2,
.rcs_shape_wizard_step-3 {
  position: relative;
}

.rcs_shape_wizard_content ul li.rcs_setting_details {
  position: absolute;
  top: 5px;
  left: 65px;
}

.rcs_shape_wizard_content ul li.rcs_setting_price {
  position: absolute;
  top: 37px;
  right: 60px;
  /* width: 60px; */
}

.rcs_shape_wizard_content ul li.rcs_setting_price p {
  margin: 0;
  font-size: 12px;
  /* font-weight: 600; */
}

.rcs_shape_wizard_content ul li.rcs_setting_details h2 {
  /* font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 4px;
  font-family: var(--font_1); */
}

.rcs_shape_wizard_content ul li.rcs_setting_details a,
.rcs_shape_wizard_content ul li.rcs_setting_details a:hover {
  text-decoration: underline;
  font-size: 12px;
}

.rcs_shape_wizard_content ul li.rcs_setting_details p {
  font-size: 12px;
}

.rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
  position: absolute;
  top: 8px;
  right: 10px;
  width: 50px;
  border-radius: 50%;
}

.rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
  /* width: 50px;
  height: 50px;
  margin: 10px auto 0; */
  display: block;
}

.rcs_diamond_table_icon {
  cursor: pointer;
}

/*Shape wizard css*/
#setting-metal-type .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  margin: 0;
}

.rcs_filter_accordion_sec .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 0 0 0 !important;
}

.rcs_mobile_check {
  position: absolute;
  right: 7px;
  top: 12px;
  font-size: 15px !important;
  z-index: 99999 !important;
}

.rcs_diamond_table .MuiTableBody-root tr th,
.rcs_diamond_table .MuiTableBody-root tr td {
  padding: 5px 16px;
  font-size: 12px;
  letter-spacing: 0 !important;
}

.rcs_fill_button_table {
  padding: 5px 20px !important;
}

.rcs_diamond_table .MuiTableBody-root tr td button {
  font-size: 12px;
}

/*Complete Rings*/
.rcs_complete_ring p {
  margin: 0 0 6px;
}

.rcs_complete_ring p span {
  cursor: pointer;
}

.rc-slider-mark-text {
  cursor: unset !important;
}

/*Complete Rings*/

/*diamond grid Css */
.rcs_diamonds_grid_content img {
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  width: 100%;
  position: relative;
  height: 285px;
  object-fit: cover;
}

.rcs_diamonds_grid_content {
  /* border: 1px solid #f1f1f1; */
  /* border-radius: 10px; */
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.rcs_product_info h3 {
  padding: 0;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--inner_font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.rcs_product_info {
  padding: 15px;
  /* position: relative; */
}
.lab-wrapper-slider {
  display: flex;
  align-items: center;
  /* padding: 0 1rem; */
}

.lab-wrapper-slider li {
  text-align: center;
}

.outer-lab-wrapper-slider{
  position: relative;
  top: -5px !important;
}
.rcs_product_tooltip span svg {
  position: absolute;
  top: 5px;
  left: 5px;
  color: var(--inner-primary);
  background-color: #ffffff;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  font-size: 1px;
}

.rcs_product_tooltip_content {
  display: none;
}

.rcs_product_info p {
  text-align: center;
  color: var(--inner-primary);
  margin: 0;
  font-family: var(--inner_font);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.rcs_product_tooltip:hover .rcs_product_tooltip_content {
  display: block !important;
}

.rcs_diamonds_grid_content:hover {
  box-shadow: 0px 4px 20px 0px #0000001a;
}

.rcs_bg-slide {
  list-style: none;
  /* background: linear-gradient(270deg, rgba(20, 52, 74, 0) 0%, var(--inner-primary) 100%); */
  background: var(--inner-primary);
  /* transition: all .7s ease; */
  /* -webkit-transition: all .7s ease; */
  width: 90%;
  height: max-content;
  margin: auto;
  /* display: grid; */
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  animation: slide-in 0.5s both;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.rcs_bg-slide li {
  color: #fff;
  font-family: var(--inner_font);
  font-size: 11px;
  font-weight: 500;
  padding-bottom: 4px;
  display: flex;
}

.rcs_bg-slide li:last-child {
  padding-bottom: 0;
}

.rcs_left-align {
  min-width: 90px;
  text-transform: capitalize;
}

.rcs_center_align {
  width: 20px;
}

.rcs_right-align {}

span.rcs_diamonds_grid_wishlist {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 99;
  color: #000;
  width: 25px;
}

span.rcs_diamonds_grid_info {
  position: absolute;
  left: 20px;
  top: 6px;
  z-index: 9;
  display: none;
}

.rcs_diamonds_grid_content:hover span.rcs_diamonds_grid_info {
  display: block;
}

span.rcs_diamonds_grid_info img {
  width: 18px;
  margin: 8px;
  cursor: pointer;
}

/*diamond grid Css */

/*Gemstone Page*/
.rcs_gemstone_color .single-item-container--Xo5ic {
  display: unset;
}

.rcs_gemstone_color .single-item-container--Xo5ic img {
  /* width: 42px; */
}

.rcs_gemstone_shape1 li span {
  font-weight: 500;
  font-size: 11px;
}

.rcs_clear_filter {
  float: right;
  border-radius: 0 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.rcs_gemstone_shape1 .single-item-container--Xo5ic {
  display: grid;
  justify-content: center;
  align-items: center;
}

.rcs_gemstone_shape1 li {
  /* padding: 15px; */
}

/*Gemstone Page*/
.btn.btn-contained {
  font-family: var(--inner_font);
  font-size: 16px;
  font-weight: 500;
  color: var(--inner-primary);
  background: #dfe4e8;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
}

.rcs_add_setting_btn {
  width: 49% !important;
  float: left !important;
  margin: 0 5px 10px 0 !important;
  display: block !important;
  /* font-size: 12px !important; */
  /* padding: 5px 0 !important; */
}

.rcs_add_setting_btn1 {
  width: 49% !important;
  float: left !important;
  margin: 0 auto 10px !important;
  display: block !important;
  /* font-size: 12px !important; */
  /* padding: 5px 0 !important; */
}

.rcs_add_setting_btn2 {
  width: 100% !important;
  margin: 0 auto 10px !important;
  display: block !important;
  /* font-size: 12px !important; */
  /* padding: 5px 0 !important; */
}

.rcs_add_setting_btn2:last-child {
  margin: 0 auto !important;
}

ul.MuiList-root.rcs_dialog_btn.MuiList-padding {
  padding: 15px !important;
}

.rcs_appointment_label label {
  display: flex !important;
}

/*Mobile view shape wizard details*/
.rcs_mobile_setting_details {
  height: 70px;
  position: absolute;
  bottom: 0;
  z-index: 5;
  top: 34px;
  left: 0;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.rcs_mobile_setting_details ul {
  display: flex;
  justify-content: center;
  align-content: center;
}

.rcs_mobile_setting_details ul li img {
  width: 50px;
}

.rcs_mobile_setting_details ul li .rcs_mobile_setting_content p {
  margin: 0;
  text-align: left;
  font-size: 14px;
  padding-left: 5px;
}

.rcs_mobile_setting_details ul li .rcs_mobile_setting_content button {
  font-size: 12px;
  padding: 0;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
}

.rcs_gemstone_color_mob img {
  width: 30px;
}

.rcs_gemstone_color_mob .single-item-container--Xo5ic {
  display: block;
}

/*Mobile view shape wizard details*/
/*Server Css*/
.rcs_filter_accordion_sec .css-u7qq7e {
  padding: 0 !important;
}

.rcs_filter_accordion_sec .css-1iji0d4.Mui-expanded {
  min-height: 44px !important;
  background-color: var(--lightbg);
}

.rcs_filter_accordion_sec .css-67l5gl.Mui-expanded {
  /* margin: 0.5rem 0 0 0 !important; */
  margin: 0 !important;
}

.rcs_filter_accordion_sec .css-67l5gl {
  box-shadow: none !important;
}

.rcs_filter_accordion_sec .css-17o5nyn.Mui-expanded {
  margin: 12px 0 !important;
}

.rcs_filter_accordion_sec .css-17o5nyn {
  flex-grow: unset !important;
}

/*Server Css*/

/*rcs fancy color diamond Css */
.rcs_fancy_color_tite h2 {
  /* margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #323232; */
}

ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul li {
  /* border: none; */
}

/* ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul li {
border: none;
padding: 0 25px 15px 0;
width: 20%;
} */
.rcs_fancy_img {
  /* border: 1px solid #0000;
  border-radius: 2px;
  width: 100%;
  padding: 7px 14px;
  margin-bottom: 0; */
}

.rcs_fancy_img img {
  /* width: 85% !important; */
}

.rcs_fan_color img {
  position: relative;
  top: 3px;
}

li.item--YBPgi.selected--Qs8si2 span {
  font-weight: 600;
}

li.item--YBPgi.selected--Qs8si2 .rcs_fancy_img {
  border-color: #000000;
}

ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul {
  /* display: grid;
  gap: 1.5rem 2rem;
  grid-template-columns: repeat(5, 2fr); */
}

.rcs_fancy_carat_tite h2 {
  /* margin: 0;
  margin-bottom: 15px;
  font-size: 14px;
  text-transform: uppercase;
  color: #323232; */
}

.rcs_intensity_tite h2 {
  /* margin: 0;
  margin-bottom: 15px;
  font-size: 14px;
  text-transform: uppercase;
  color: #323232; */
}

.rcs_carat_slider .rc-slider {
  /* margin-left: 10px; */
}

.rcs_price_slider .rc-slider {
  /* margin-left: 10px; */
}

.rcs_intensity_slider .rc-slider {
  /* margin-left: 10px; */
}

.rcs_fancy_price_tite h2 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 14px;
  text-transform: uppercase;
  color: #323232;
}

.rcs_clarity_slider .rc-slider.rc-slider-with-marks {
  /* margin-left: 10px; */
}

ul.rcs_price_range_input.rcs_prince_input_diamond li .input-group input.form-control {
  width: 120px;
  height: 35px;
  border-radius: 7px;
  border: 1px solid #00000012;
  background: transparent;
}

.rcs_intensity_slider_img {
  margin: 10px 12px 10px 32px;
}

.rcs_intensity_slider_img ul {
  display: flex;
  padding: 0px 0px;
  justify-content: space-between;
}

.rcs_intensity_slider_img ul li {
  text-align: center;
}

.rcs_intensity_slider_text p {
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  font-family: var(--inner_font);
}

.rcs_intensity_slider_img_d img {
  width: 100%;
}

.rcs_intensity_slider_img_d {
  width: 55px;
  margin: auto;
}

.rcs_intensity_slider_img_d_horizontal {
  width: 30px;
}

.rcs_intensity_slider .rc-slider .rc-slider-track.rc-slider-track-1 {
  /* height: 10px; */
}

.rcs_intensity_slider .rc-slider .rc-slider-rail {
  /* height: 10px; */
}

.rcs_intensity_slider .rc-slider .rc-slider-track {
  background-attachment: fixed;
  background-size: 50%;
  max-width: 600px !important;
}

.rcs_intensity_slider .rc-slider.yellow .rc-slider-track {
  /* background-image: url(../images/stripe/yellow_bg.png); */
}

.rcs_intensity_slider .rc-slider.orange .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/orange_bg.png);
}

.rcs_intensity_slider .rc-slider.pink .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/pink_bg.png);
}

.rcs_intensity_slider .rc-slider.blue .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/blue_bg.png);
}

.rcs_intensity_slider .rc-slider.green .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/green_bg.png);
}

.rcs_intensity_slider .rc-slider.brown .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/brown_bg.png);
}

.rcs_intensity_slider .rc-slider.red .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/red_bg.png);
}

.rcs_intensity_slider .rc-slider.purple .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/purple_bg.png);
}

.rcs_intensity_slider .rc-slider.black .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/black_bg.png);
}

.rcs_intensity_slider .rc-slider.other .rc-slider-track {
  background-image: url(https://dl2vs6wk2ewna.cloudfront.net/showcase/images/stripe/other_bg.png);
}

.rcs_clarity_slider .rc-slider-mark {
  left: -30px;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .rcs_clarity_slider .rc-slider-mark {
    left: -20px;
  }
  .rcs_intensity_slider_img {
    margin: 0;
}
}

.rcs_intensity_slider .rc-slider .rc-slider-handle.rc-slider-handle-1 {
  /* margin-top: -6px !important; */
}

.rcs_intensity_slider .rc-slider .rc-slider-handle.rc-slider-handle-2 {
  /* margin-top: -6px !important; */
}

.rcs_price_slider .rc-slider .rc-slider-track.rc-slider-track-1 {
  /* height: 10px; */
}

.rcs_price_slider .rc-slider .rc-slider-rail {
  /* height: 10px; */
}

.rcs_price_slider .rc-slider .rc-slider-handle.rc-slider-handle-1 {
  /* margin-top: -6px !important; */
}

.rcs_price_slider .rc-slider .rc-slider-handle.rc-slider-handle-2 {
  /* margin-top: -6px !important; */
}

.rcs_carat_slider .rc-slider .rc-slider-track.rc-slider-track-1 {
  /* height: 10px; */
}

.rcs_carat_slider .rc-slider .rc-slider-rail {
  /* height: 10px; */
}

.rcs_carat_slider .rc-slider .rc-slider-handle.rc-slider-handle-1 {
  /* margin-top: -6px !important; */
}

.rcs_carat_slider .rc-slider .rc-slider-handle.rc-slider-handle-2 {
  /* margin-top: -6px !important; */
}

.rcs_clarity_slider .rc-slider .rc-slider-track.rc-slider-track-1 {
  /* height: 10px; */
}

.rcs_clarity_slider .rc-slider .rc-slider-rail {
  /* height: 10px; */
}

.rcs_clarity_slider .rc-slider .rc-slider-handle.rc-slider-handle-1 {
  /* margin-top: -6px !important; */
}

.rcs_clarity_slider .rc-slider .rc-slider-handle.rc-slider-handle-2 {
  /* margin-top: -6px !important; */
}

.rcs_clarity_slider .rc-slider .rc-slider-step .rc-slider-dot {
  position: absolute;
  bottom: 0px;
  margin-left: -4px;
  width: 8px;
  height: 10px !important;
  border: 2px solid #e9e9e9;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rcs_clarity_slider .rc-slider .rc-slider-step {
  height: 10px;
}

.rcs_intensity_opc_low {
  opacity: 0.4;
}

.rcs_gia_reports img {
  width: auto;
  /* width: 113px; */
  /* height: 50px; */
  /* margin: 0 auto 0 0; */
}

.rcs_gia_reports a {
  /* margin: 20px 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

/*rcs fancy color diamond Css */
.rcs_ring_setting_style_disable {
  pointer-events: none;
  opacity: 0.5 !important;
}

/*New Tabel Css*/
.rcs_diamonds_table {
  text-align: center;
  /* border: 1px solid #dee2e6; */
}

.rcs_diamonds_table thead tr th {
  padding: 10px;
  text-align: left;
  color: #000;
  /* text-transform: uppercase; */
}

.rcs_diamonds_table tbody tr td {
  font-size: 14px;
  font-family: var(--inner_font);
  padding: 5px 10px;
  vertical-align: middle;
  border: 0;
  text-align: left;
  color: #000;
  border: 1px solid #fff;
  min-width: 110px;
}

.rcs_diamonds_table button.btn.btn-contained {
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
}

.rcs_diamonds_table tbody tr:nth-of-type(even) td,
.rcs_diamonds_table tbody tr:hover td {
  background-color: #f5f8f9;
}

.rcs_diamonds_table tbody tr:hover button {
  background-color: var(--inner-primary);
  color: #fff;
}

.rcs_diamond_table thead tr th {
  font-family: var(--inner_font);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
  background: var(--inner-primary);
  color: #ffffff;
  border: 1px solid #fff;
}

/*New Tabel Css*/
/*Responsive css*/
@media (min-width: 992px) {
  .steps-1-2-3-block .step .title strong {
    font-size: 16px;
  }

  .steps-1-2-3-block .step .title span,
  .steps-1-2-3-block .step .title a {
    padding-right: 1.25rem;
  }

  .big-ring-settings {
    /* margin: 1rem 0 1rem 0 !important; */
  }

  .grid-topbar-elem-list {
    display: -ms-flexbox;
    display: flex;
    /* margin: 0 0 1.5rem 0; */
    padding: 0;
  }

  .big-ring-settings>li {
    margin-top: 0;
  }

  .grid-topbar-elem-list li {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    width: 100%;
    float: none;
    cursor: pointer;
    text-transform: capitalize;
  }

  .grid-topbar-elem-list li .rcs_ring_setting_style {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    width: 100%;
    text-decoration: none;
  }

  .rcs_ring_setting_style_disable {
    pointer-events: none;
    opacity: 0.5 !important;
  }

  .grid-topbar-elem-list li i,
  .grid-topbar-elem-list li i::before {
    font-size: 80px;
    line-height: 40px;
  }

  .grid-topbar-elem-list.rcs_diamond_fitler li i,
  .grid-topbar-elem-list.rcs_diamond_fitler li i::before {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .steps-1-2-3-block:first-child {
    margin-left: 0;
  }

  .steps-1-2-3-block {
    margin-left: -15px;
  }

  .steps-1-2-3-block>.step {
    width: calc(100% - 15px);
    height: 100px;
  }

  .steps-1-2-3-block .step .step-number {
    font-size: 42px;
    padding-left: 30px;
  }

  .steps-1-2-3-block:first-child .step .title {
    padding: 0 0 0 10px;
  }

  .steps-1-2-3-block .step .title {
    padding: 0 0 0 10px;
    width: 100%;
    text-align: left;
  }

  .steps-1-2-3-block .step .title strong {
    font-size: 16px;
    text-align: left;
    text-transform: uppercase;
  }

  .steps-1-2-3-block .step .title span,
  .steps-1-2-3-block .step .title a {
    font-size: 12px;
    line-height: 1.2;
    margin-top: 0.25rem;
    padding-right: 0.625rem;
  }

  .steps-1-2-3-block .step .image {
    z-index: 100;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 10;
  }

  .steps-1-2-3-block .step .rhombus,
  .steps-1-2-3-block .step .rhombus-top {
    right: -21px;
  }

  .steps-1-2-3-block .step .rhombus::before,
  .steps-1-2-3-block .step .rhombus-top::before {
    top: 50px;
    left: -20px;
  }

  .steps-1-2-3-block.active .step .rhombus-top {
    right: -20px;
  }

  .steps-1-2-3-block.active .step .rhombus-top::before {
    top: 50px;
    left: -20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .rcs_diamond_content {
    text-align: center;
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi:nth-child(5),
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi:nth-child(5) {
    border-right: none !important;
  }

  .single-item-container--Xo5ic.gemstone_shape img {
    height: 55px !important;
    padding: 10px !important;
  }

  .rcs_intensity_slider .rc-slider .rc-slider-track {
    background-attachment: fixed;
    background-size: 100%;
    max-width: 600px !important;
  }

  .rcs_clarity_slider .rc-slider-mark {
    left: -20px;
  }

  .rcs_intensity_slider_img {
    margin: 10px 0px 10px 10px;
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_shape1.rcs_fancy_color_mobile li.item--YBPgi {
    /* padding: 5px;
    width: 25%;
    padding-bottom: 0; */
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_shape1.rcs_fancy_color_mobile li.item--YBPgi .rcs_fancy_img {
    padding: 5px 5px;
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_shape1.rcs_fancy_color_shape_mobile li.item--YBPgi {
    /* padding: 5px;
    width: 25%;
    padding-bottom: 0; */
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_shape1.rcs_fancy_color_shape_mobile li.item--YBPgi .rcs_fancy_img {
    padding: 5px 5px;
  }

  .rcs_intensity_slider_img_d {
    width: 30px;
    margin: auto;
  }

  .rcs_intensity_slider_text p {
    /* margin: 0; */
    font-size: 9px;
    /* font-weight: 500; */
  }

  ul.rcs_price_range_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 10px;
  }

  ul.rcs_price_range_input.rcs_prince_input_diamond.rcs_prince_input_diamond1 {
    margin: 0px;
    padding-top: 10px;
  }

  .rcs_clarity_slider {
    /* margin-bottom: 40px; */
  }

  .rcs_diamond_table .MuiTableCell-head,
  .rcs_diamonds_table thead tr th {
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .rcs_diamond_table p {
    text-align: center !important;
  }

  .rcs_diamond_table {
    margin: 0 !important;
  }

  .rcs_sort_filter {
    float: unset !important;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .rcs_diamond_content p {
    font-size: 12px;
  }

  .rcs_diamond_content h1 {
    font-size: 20px;
  }

  .rcs_prince_input_diamond .form-control,
  .rcs_price_range_input .form-control {
    font-size: 13px !important;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter {
    width: 100% !important;
    justify-content: space-between;
  }

  .big-ring-settings-wrap .big-ring-settings,
  .big-metal-types-wrap .big-metal-types {
    display: block;
  }

  .big-metal-types-wrap .big-metal-types li {
    display: block;
    text-align: center;
    width: 25%;
    float: left;
  }

  .big-ring-settings-wrap .big-ring-settings li {
    display: block;
    text-align: center;
    width: 33.33%;
    float: left;
  }

  .big-ring-settings-wrap1 .big-ring-settings li {
    display: block;
    text-align: center;
    width: 25%;
    float: left;
  }

  .big-ring-settings-wrap1 .big-ring-settings li .icon--qAfox {
    padding: 0;
  }

  .steps-1-2-3-block .step .title strong {
    font-size: 10px;
    font-weight: bold;
  }

  .rcs_diamond_filter_section h2 {
    margin: 0 0px 10px -10px;
  }

  .rcs_fluorescence_slider .rc-slider-mark {
    left: -25px !important;
  }

  .rc-slider-mark {
    font-size: 10px;
  }

  .rcs_cut_slider .rc-slider-mark {
    /* left: -37px !important; */
    left: -13% !important;
  }

  .rcs_color_slider .rc-slider-mark {
    left: -15px !important;
  }

  li.longFilter--VqAB5.item--YBPgi span img {
    /* width: 35px !important;
      height: 35px !important;
      line-height: 35px !important;
      margin-top: 5px; */
  }

  .rcs_diamond_filter_section_desktop {
    display: none;
  }

  .rcs_diamond_filter_section h2 {
    margin: 3px 0px 7px 0;
    font-size: 13px;
  }

  .rcs_diamond_table .MuiTableBody-root tr th,
  .rcs_diamond_table .MuiTableBody-root tr td {
    padding: 5px 16px;
    font-size: 12px;
  }

  .rcs_fill_button_table {
    padding: 5px 20px !important;
  }

  .mobile_device_pad_none {
    padding: 0 !important;
  }

  .rcs_add_setting_btn,
  .rcs_add_setting_btn1 {
    width: 100% !important;
    float: unset !important;
  }

  .rcs_dialog_btn1 .MuiDialog-paperWidthSm {
    max-width: 100% !important;
    width: 80%;
  }

  .rcs_gemstone_shape1 {
    width: 100%;
    display: block;
  }

  .rcs_gemstone_shape1 li {
    width: 33%;
    display: block;
    margin: 0 auto;
    float: left;
    border: none;
  }

  .rcs_gemstone_shape1 li:last-child {
    border: none;
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape {
    /* display: inline-block !important; */
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi {
    /* width: 20% !important; */
    /* float: left !important; */
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi:first-child,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi:nth-child(5),
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi:nth-child(5) {
    border-right: 1px solid #dfdfdf;
    /* margin-bottom: 35px; */
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi:last-child,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rcs_gemstone_color_mob img {
    width: 47px;
  }

  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi.selected--Qs8si:first-child,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_mob .item--YBPgi.selected--Qs8si:last-child,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi.selected--Qs8si:first-child,
  .action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color_shape .item--YBPgi.selected--Qs8si:last-child {
    border-radius: 4px;
  }

  /* .rcs_gemstone_color_mob .title--jqbwB {
      display: block !important;
      top: 50px;
      left: 0;
      right: 0;
      font-size: 11.5px !important;
  } */

  .rcs_gemstone_color_shape .title--jqbwB {
    /* display: block !important;
      top: 50px;
      left: 0;
      right: 0;
      font-size: 11.5px !important; */
  }

  .rcs_gemstone_color_mob .item--YBPgi.selected--Qs8si .title--jqbwB,
  .rcs_gemstone_color_shape .item--YBPgi.selected--Qs8si .title--jqbwB {
    font-weight: bold !important;
  }

  .rcs_mobile_view_cart {
    display: block !important;
  }

  .rcs_combo_remove li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .rcs_combo_remove li svg {
    font-size: 16px;
  }

  .rcs_cart_main {
    padding: 10px;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter span {
    font-size: 12px;
    margin-top: 3px;
  }

  .rcs_ring_setting_style_mobile img {
    /* width: 65px !important; */
  }

  .rc-slider-mark-text-active {
    /* font-size: 10px !important; */
  }

  .rcs_filter_accordion_sec_diamond .MuiTypography-root.MuiTypography-body1,
  .rcs_filter_accordion_sec .MuiTypography-root.MuiTypography-body1 {
    font-size: 13px;
    /* width: 100%; */
  }
  .rcs_filter_accordion_sec.rcs_filter_accordion_sec_diamond .MuiAccordion-root::before{
display: none;
  }
  .rcs_filter_accordion_sec.rcs_filter_accordion_sec_diamond > div > div.Mui-expanded {
    min-height: 48px !important;
  }
  .rcs_diamonds_page_top_btn ul {
    display: block !important;
  }

  .rcs_diamonds_page_top_btn ul li button {
    border-radius: 5px !important;
    margin: 10px 0 0;
  }

  .rcs_diamonds_page_top_btn {
    margin: 15px 0 10px;
  }

  .rcs_ringsetting_section.container {
    padding: 0;
  }

  .rcs_intensity_slider_img {
    margin: 10px 0px;
  }

  .rcs_intensity_slider_img {
    /* margin: 10px -20px 10px 0px; */
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .big-ring-settings-wrap .big-ring-settings {
    display: block;
  }

  .big-ring-settings-wrap .big-ring-settings li {
    display: block;
    text-align: center;
    width: 33.3333%;
    float: left;
  }

  .rcs_diamond_filter_section_desktop {
    display: none;
  }

  .rcs_diamond_filter_section h2 {
    margin: 0;
  }

  .rcs_cut_slider .rc-slider-mark {
    left: -13% !important;
  }

  .rcs_fluorescence_slider .rc-slider-mark {
    left: -10% !important;
  }

  .rcs_gemstone_shape1 {
    width: 100%;
    display: block;
  }

  .rcs_gemstone_shape1 li {
    width: 20%;
    display: block;
    margin: 0 auto;
    float: left;
    border: none;
  }

  .rcs_gemstone_shape1 li:last-child {
    border: none;
  }

  .rcs_gemstone_color_mob img {
    width: 40px;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter span {
    font-size: 12px;
    margin-top: 3px;
  }

  .rcs_ring_setting_style_mobile img {
    width: 65px !important;
  }

  .rc-slider-mark-text-active {
    font-size: 10px !important;
  }

  .rcs_filter_accordion_sec_diamond .MuiTypography-root.MuiTypography-body1 {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }

  .rcs_intensity_slider_text p {
    font-size: 9px;
  }

  .rcs_intensity_slider_img {
    margin: 10px 0px 10px 10px;
    width: 100%;
  }

  .rcs_diamonds_table thead tr th {
    font-size: 12px;
    padding: 13px 0;
  }

  .rcs_clarity_slider .rc-slider-mark span {
    font-size: 9px;
  }

  .rcs_clarity_slider .rc-slider-mark {
    left: -20px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details a,
  .rcs_shape_wizard_content ul li.rcs_setting_details a:hover {
    font-size: 9px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details p {
    font-size: 12px;
    margin: 4px 0 0;
  }

  .grid-topbar-elem-list li span {
    font-size: 10px;
  }

  .rcs_prince_input_diamond .form-control,
  .rcs_price_range_input .form-control {
    font-size: 13px !important;
  }

  /* .grid-topbar-elem-list li {
      width: 11.11%;
  } */
  #setting-metal-type .grid-topbar-elem-list li {
    /* width: 20%; */
  }

  .grid-topbar-elem-list li i,
  .grid-topbar-elem-list li i::before {
    font-size: 60px;
    line-height: 40px;
  }

  .big-ring-settings>li {
    margin: 0;
  }

  .setting-shapes .grid-topbar-elem-list li {
    width: 10%;
  }

  .big-ring-settings>li>a {
    font-size: 12px;
  }

  .rcs_diamond_content p {
    font-size: 14px;
  }

  .rcs_diamond_content h1 {
    font-size: 20px;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter {
    width: 100% !important;
  }

  .rcs_diamond_filter_section h2 {
    margin: 0;
    margin-bottom: 10px;
  }

  .rcs_fluorescence_slider .rc-slider-mark {
    left: -32px !important;
  }

  .rcs_cut_slider .rc-slider-mark {
    left: -43px !important;
  }

  .rcs_color_slider .rc-slider-mark {
    left: -18px !important;
  }

  .rcs_gemstone_shape1 li {
    padding: 4px;
  }

  .rcs_gemstone_color .single-item-container--Xo5ic img {
    width: 33px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price p {
    font-size: 9px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    position: absolute;
    top: 40px;
    right: -12px;
    width: 60px;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter span {
    font-size: 12px;
    margin-top: 3px;
  }

  .rcs_ring_setting_style_mobile img {
    width: 65px !important;
  }

  .rc-slider-mark-text-active {
    font-size: 11px !important;
  }

  .rcs_filter_accordion_sec_diamond .MuiTypography-root.MuiTypography-body1 {
    font-size: 13px;
  }

  /* .rcs_ringsetting_section .slick-slider.rcs_list_slider.slick-initialized {
      width: 230px;
  }
  .rcs_ringsetting_section .rcs_product_item a {
      height: 230px !important;
  } */

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul li span {
    font-size: 12px;
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul {
    display: flex;
    grid-gap: 1.5rem 2rem;
    gap: 1.5rem 0.7rem;
    grid-template-columns: repeat(10, 2fr);
    margin-top: 10px;
  }

  .rcs_fancy_img img {
    width: 100%;
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul li span {
    font-size: 12px;
  }

  .rcs_diamonds_page_top_btn ul li button {
    padding: 10px 20px;
    width: 200px;
    margin: 20px 0 40px;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .ringBuilderStepsMenu {
    width: 100% !important;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    top: 45px;
    right: -14px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    top: 14px;
    left: 43px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    top: 16px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 4px;
    right: 6px;
    width: 40px;
    height: 40px; */
  }

  .rcs_intensity_slider_img_d {
    width: 40px;
    margin: auto;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 22px;
    height: 22px;
    margin: 6px 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    /* top: 32px; */
    right: -13px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 5px;
    left: 25px;
    width: 150px;
    line-height: 1; */
  }

  .ringBuildermenusBox .ArrowRigt {
    /* right: 6px !important;
    top: 0px !important;
    height: 55px; */
  }

  .rcs_diamonds_grid_content img {
    height: 226px;
  }
}

@media (min-width: 801px) and (max-width: 870px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 8px; */
    /* right: 19px; */
    /* width: 43px; */
    /* height: 43px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 20px !important; */
    /* height: 27px; */
    /* margin: 8px auto 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 5px;
    left: 25px;
    width: 140px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    position: absolute;
    top: 13px;
    left: 56px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    top: 44px;
    right: -10px;
  }

  .headingTopRingBuilderMenus h3 {
    /* font-size: 20px !important; */
  }

  .ringBuildermenusBox .rcs_shpae_padding.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 871px) and (max-width: 920px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 4px;
    right: 7px;
    width: 47px;
    height: 47px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 30px;
    height: 29px;
    margin: 9px auto 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    top: 10px;
    /* 
    left: 55px;
    width: 140px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    /* top: 24px;
    right: 50px; */
  }
}

@media (min-width: 921px) and (max-width: 991px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 14px;
    right: 9px;
    width: 51px;
    height: 51px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 33px;
    height: 33px;
    margin: 10px auto 0; */
  }

  /* .rcs_shape_wizard_content ul li.rcs_setting_details {
    top: 5px;
    left: 60px;
    width: 140px;
  } */

  /* .rcs_shape_wizard_content ul li.rcs_setting_price {
    top: 24px;
    right: 32px;
  } */
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rcs_ringsetting_section .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 290px !important;
    border: 0px;
  }

  .rcs_mob_filter1.rcs_ring_setting_filter {
    width: 100% !important;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    font-size: 13px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    margin-bottom: 0;
    font-weight: 600;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details a,
  .rcs_shape_wizard_content ul li.rcs_setting_details a:hover {
    font-size: 12px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details p {
    font-size: 12px;
    margin: 13px 0 0;
  }

  .grid-topbar-elem-list li span {
    font-size: 10px;
  }

  .rcs_prince_input_diamond .form-control,
  .rcs_price_range_input .form-control {
    font-size: 13px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rcs_fluorescence_slider .rc-slider-mark {
    left: -11% !important;
  }

  .rcs_cut_slider .rc-slider-mark {
    left: -14% !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 5px;
    right: 9px;
    width: 51px;
    height: 51px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 33px;
    height: 33px;
    margin: 10px auto 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 5px;
    left: 60px;
    width: 140px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    /* top: 30px;
    right: 60px; */
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .ringBuildermenusBox .rcs_shpae_padding.col-3 {
    /* flex: 0 0 25%; */
    /* max-width: 25%; */
  }

  .rcs_prince_input_diamond .input-group,
  .rcs_price_range_input .input-group {
    width: 90%;
    margin-left: 5px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 6px;
    right: 8px;
    width: 55px;
    height: 55px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 33px;
    height: 36px;
    margin: 10px auto 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 10px; */
    /* left: 60px; */
    /* left: 70px; */
    /* width: 140px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    right: 38px;
    top: 31px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul {
    /* display: grid;
    grid-gap: 1.5rem 2rem;
    gap: 0.7rem 1rem;
    grid-template-columns: repeat(5, 2fr); */
  }

  .single-item-container--Xo5ic.gemstone_shape img {
    height: 40px !important;
    padding: 5px !important;
  }

  .rcs_clarity_slider .rc-slider-mark {
    /* left: -25px; */
  }

  .rcs_fancy_img img {
    /* width: 100%; */
  }

  ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul li span {
    /* font-size: 14px; */
  }
}

@media (min-width: 1101px) and (max-width: 1149px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 5px;
    right: 9px;
    width: 59px;
    height: 59px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 36px;
    height: 36px;
    margin: 13px auto 0; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 11px;
    left: 70px;
    width: 140px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    right: 34px;
  }
}

@media (min-width: 1150px) and (max-width: 1199px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 5px;
    right: 10px;
    width: 62px;
    height: 62px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 36px;
    height: 36px;
    margin: 13px auto 0; */
  }

  .StepsNum {
    padding-left: 20px !important;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    top: 7px;
    left: 57px;
  }

  /* .rcs_shape_wizard_content ul li.rcs_setting_price {
    top: 35px;
    right: 75px;
  } */
}

@media (min-width: 1200px) and (max-width: 1440px) {
  ul.rcs_price_range_input.rcs_prince_input_diamond.rcs_prince_input_diamond1 {
    margin: 5px 5px 0 25px;
  }

  .single-item-container--Xo5ic.gemstone_shape img {
    height: 65px !important;
    padding: 15px !important;
  }

  /* .single-item-container--Xo5ic.gemstone_shape img {
    height: 50px !important;
  } */
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* top: 4px;
    right: 10px;
    width: 67px;
    height: 67px; */
  }
}

@media (min-width: 1251px) and (max-width: 1280px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    /* right: 45px */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    /* right: 63px; */
  }
}

@media (min-width: 1151px) and (max-width: 1350px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    /* width: 30px;
    height: 30px; */
  }

  /* ul.action-area--soLSw.table-align_filter--GVxiB.rcs_gemstone_color.rcs_fancy_color_ul {
    display: grid;
    grid-gap: 1.5rem 2rem;
    gap: 1rem 2rem;
    grid-template-columns: repeat(5, 2fr);
  } */

  .rcs_diamond_content h1 {
    /* font-size: 30px; */
  }

  .rcs_diamond_content p {
    /* font-size: 14px; */
  }

  .rcs_intensity_slider_img {
    margin: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  ul.rcs_price_range_input {
    margin: 5px 7px;
  }
}

@media (min-width: 1200px) {
  .padding_for_desktop {
    padding: 0 70px;
  }

  .video-wrapper video,
  .video-wrapper,
  .image-to-magnify {
    height: 650px !important;
    /* max-width: 536.666px !important; */
    margin: auto !important;
    /* object-fit: contain; */
    display: table;
  }

  .video-wrapper.titleViewData video,
  .video-wrapper,
  .image-to-magnify {
    height: auto !important;
  }


  /*List item css*/
  .rcs_ringsetting_section .slick-slider.rcs_list_slider.slick-initialized {
    max-width: 300px;
    width: 300px;
    border: 0px;
  }

  .rcs_metal_shape {
    /* padding-right: 100px !important; */
  }

  /*List item css*/

  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    font-family: var(--inner_font);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 4px;
    letter-spacing: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1479px) {

  .video-wrapper video,
  .video-wrapper,
  .image-to-magnify {
    width: 100% !important;
    height: 530px !important;
    /* max-width: 536.666px !important; */
    margin: auto !important;
    /* object-fit: contain; */
  }

  .video-wrapper.titleViewData video,
  .video-wrapper,
  .image-to-magnify {
    height: auto !important;
  }
}

/*Responsive css*/

.rc-slider-track {
  background-color: var(--inner-primary) !important;
}

.rcs_price_range_input1 input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  font-family: var(--inner_font);
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding-left: 0 !important;
  height: 42px !important;
}

.rcs_price_range_input1 span.input-group-text {
  border: 1px solid #00000012;
  border-right: 0;
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.rc-slider-mark-text-active {
  color: #000 !important;
  font-family: var(--inner_font);
  font-size: 12px;
  font-weight: 400;
}

.StepsNum {
  font-family: var(--inner_font);
  font-size: 40px;
  font-weight: 500;
  width: 100%;
  height: 65px;
  line-height: 65px;
  padding-left: 30px;
}

.StepsNum.active {
  color: var(--inner-primary);
  border: 2px solid var(--inner-primary);
  border-right: 0;
  border-radius: 5px;
  background: #fff;
}

.ringBuildermenusBox .row .col-3:nth-of-type(1) .StepsNum {
  border-left: 2px solid #f3f3f3;
}

/* .ringBuildermenusBox .row .col-3:nth-of-type(2) .ArrowRigt {
  opacity: 0;
}
.ringBuildermenusBox .row .col-3:nth-of-type(2) .ArrowRigt.active {
  opacity: 1;
} */

.ringBuildermenusBox .row .col-3:nth-of-type(1) .StepsNum.active {
  border-left: 2px solid var(--inner-primary);
}

.rcs_shpae_padding.col-3 {
  border: 2px solid #f3f3f3;
  border-radius: 5px 0px 0 5px;
  border-right: 0;
  border-left: 0;
}

.rcs_shape_wizard .row .col-3:last-of-type {
  border-radius: 0px 5px 5px 0px;
}

.rcs_shape_wizard .row .col-3:nth-of-type(2) {
  border-left: 2px solid #f3f3f3;
}

.ringBuildermenusBox {
  /* max-width: 90%; */
  /* background: #F5F8F9; */
  margin: 40px auto 18px;
  /* padding: 10px 30px; */
  padding: 0;
  /* border: 1px solid var(--inner-primary); */
}

.headingTopRingBuilderMenus span {
  font-family: var(--inner_font);
  font-size: 18px;
  font-weight: 400;
  color: var(--inner-primary);
  text-transform: uppercase;
}

.headingTopRingBuilderMenus h3 {
  font-family: var(--inner_font);
  font-size: 26px;
  font-weight: 500;
  color: var(--inner-primary);
  margin: 0;
}

.ringBuildermenusBox .ArrowRigt {
  position: absolute;
  right: -18px;
  top: 2px;
  font-size: 15px;
}

.ringBuildermenusBox svg.ArrowRigt.active {
  right: -16px;
}

.ringBuildermenusBox svg.ArrowRigt.active path {
  stroke: var(--inner-primary);
  opacity: 1;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .rcs_intensity_slider_text p {
    font-size: 9px;
  }

  .rcs_product_info h3 {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 5px;
  }

  .rcs_bg-slide {
    top: 18px;
  }

  .rcs_product_tooltip span svg {
    height: 20px;
    width: 20px;
  }

  .rc-slider-mark-text-active,
  .rc-slider-mark {
    font-size: 10px !important;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 65px;
    height: 65px;
    padding: 8px;
  }

  .ringBuildermenusBox {
    /* margin: 15px 0px 0; */
    /* padding: 10px 30px; */
  }

  .headingTopRingBuilderMenus h3 {
    font-size: 20px;
  }

  .headingTopRingBuilderMenus span {
    font-size: 14px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    font-size: 16px;
  }

  .StepsNum {
    font-size: 40px;
  }

  .rcs_diamonds_grid_content img {
    /* height: 260px; */
    height: 185px;
  }

  .rcs_diamonds_grid_content {
    margin-bottom: 10px;
  }

  .rcs_product_info {
    padding: 6px;
  }

  .rcs_product_info p {
    font-size: 16px;
  }

  .rcs_bg-slide li {
    font-size: 9px;
    padding-bottom: 0;
  }

  .rcs_gemstone_shape1 li img {
    /* max-width: 50px; */
  }

  .engList_filter_shape {
    /* width: 45px !important; */
  }

  li.longFilter--VqAB5.item--YBPgi span img {
    width: 60px;
    height: 60px;
    line-height: 60px;
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .engListFilterShape img {
    /* width: 45px !important; */
  }

  .ringBuildermenusBox {
    margin: 16px 0px;
    /* padding: 10px 12px; */
    /* background: transparent;
    border: 0; */
    /* display: none; */
  }

  .headingTopRingBuilderMenus h3 {
    font-size: 24px;
  }

  .StepsNum {
    font-size: 30px;
  }
}

li.rcs_ring_setting_style.rcs_ring_setting_style_mobile img {
  width: 100%;
  height: 35px;
  object-fit: contain;
  line-height: 45px;
  padding: 0 5px;
}

li.rcs_price_range_input1 input {
  background-color: transparent;
}

.rcs_sorting {
  position: relative;
  top: 3px;
}

@media (min-width: 800px) and (max-width: 991px) {
  .ringBuildermenusBox {
    margin: 0;
    padding: 5px;
    margin-top: 10px;
  }

  .StepsNum {
    /* font-size: 30px; */
  }

  .ringBuildermenusBox .ArrowRigt {
    /* opacity: 0; */
  }

  .showcase-container.rcs_custom_home_container .col-lg-4.col-md-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  .ringBuildermenusBox {
    /* margin: 20px 0; */
    /* padding: 10px 10px; */
  }

  .StepsNum {
    padding-left: 21px;
    font-size: 30px;
    /* padding-top: 10px; */
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details {
    /* top: 7px;
    left: 30px; */
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    width: 30px;
    height: 30px;
    margin: 0;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    width: 25px;
  }

  .ringBuildermenusBox .ArrowRigt {
    /* position: absolute;
    right: 6px;
    top: 0px; */
  }
}

.fancyColorFilterShape img {
  width: 55px !important;
}

@media (min-width: 1151px) and (max-width: 1350px) {
  .fancyColorFilterShape img {
    width: 85% !important;
  }
}

@media (min-width: 991px) and (max-width: 1150px) {
  .fancyColorFilterShape img {
    width: 85% !important;
  }

  .rcs_intensity_slider_img {
    margin: 0;
  }

  .rcs_intensity_slider_img_d img {
    width: 32px;
  }

  .rcs_intensity_slider_img ul li {
    min-width: 32px;
  }

  ul.rcs_price_range_input.rcs_prince_input_diamond.rcs_prince_input_diamond1 {
    margin: 8px 13px 0;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .rcs_diamonds_grid_content img {
    height: 252px;
  }

  .headingTopRingBuilderMenus h3 {
    font-size: 25px;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
  .ringBuildermenusBox {
    /* margin: 15px 0; */
    /* padding: 10px 20px; */
  }

  .rcs_product_list_wrapper {
    padding: 0px;
  }

  .rcs_bg-slide li {
    font-size: 13px;
    padding-bottom: 2px;
  }

  .rcs_diamonds_grid_content img {
    height: 290px;
  }

  .rcs_ring_setting_style_mobile img {
    width: 55px !important;
  }

  .engList_filter_shape,
  li.longFilter--VqAB5.item--YBPgi span img {
    height: auto;
    min-height: 45px;
    padding: 2px;
    width: 100%;
  }

  .engList_filter_metalType {
    width: 35px !important;
  }

  .rcs_diamonds_grid_content img {
    height: 250px !important;
  }

  .video-wrapper video,
  .video-wrapper,
  .image-to-magnify {
    height: 485px !important;
  }

  .video-wrapper.titleViewData video,
  .video-wrapper,
  .image-to-magnify {
    height: auto !important;
  }

  .rc-slider-mark-text-active {
    font-size: 9px;
  }

  .rcs_product_info p {
    font-size: 16px;
  }

  .rcs_product_info {
    padding: 8px;
  }

  .rcs_diamonds_grid_content {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .rcs_diamonds_grid_content:hover .rcs_product_tooltip_content {
    display: none !important;
  }

  .rcs_diamonds_grid_content:hover {
    box-shadow: none;
  }

  .rcs_diamonds_table tbody tr td {
    font-size: 14px;
    padding: 2px 10px;
    white-space: nowrap;
  }

  .rcs_diamonds_table tbody tr td svg {
    width: 15px;
    height: 15px;
  }

  .rcs_diamonds_table tbody tr:nth-of-type(even) td,
  .rcs_diamonds_table tbody tr:hover td {
    background-color: #f5f8f9;
  }

  .rcs_diamonds_table tbody tr .btn.btn-contained {
    font-size: 12px;
    padding: 2px 6px;
    margin: 5px;
    width: 100% !important;
    box-shadow: none;
    height: auto;
  }

  .rcs_product_info {
    padding: 8px;
  }

  .rcs_diamonds_grid_content {
    margin-bottom: 15px;
  }

  .rcs_product_tooltip span svg {
    opacity: 0;
  }

  .icon--qAfox {
    padding: 0px;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  .headingTopRingBuilderMenus h3 {
    font-size: 25px;
  }

  .ringBuildermenusBox {
    margin: 30px 0 20px;
  }

  li.longFilter--VqAB5.item--YBPgi span img {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }

  ul.rcs_price_range_input {
    margin-bottom: -6px !important;
  }

  .rcs_diamonds_grid_content img {
    height: 220px;
  }

  .rcs_diamonds_grid_content {
    margin-bottom: 10px;
  }

  .rcs_product_info {
    padding: 8px;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  li.longFilter--VqAB5.item--YBPgi span img {
    /* width: 59px; */
    /* height: 59px; */
    /* line-height: 59px; */
  }
}

@media (min-width: 1900px) {
  li.longFilter--VqAB5.item--YBPgi span img {
    width: 88px;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
  .StepsNum {
    font-size: 40px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_details h2 {
    font-family: var(--inner_font);
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 4px;
    letter-spacing: 0;
    font-size: 14px;
  }

  .headingTopRingBuilderMenus h3 {
    font-size: 25px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img img {
    width: 30px;
    height: 30px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    position: absolute;
    top: 20px;
    right: 0;
    width: 30px;
    border-radius: 50%;
  }
}

.ringBuilderStepsMenu {
  /* width: 90%; */
  padding-right: 4%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .rcs_shpae_padding.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .single-item-container--Xo5ic.gemstone_shape img {
    height: 55px !important;
    padding: 10px !important;
  }

  .rcs_shape_wizard .col-md-3 {
    display: none !important;
  }

  .ringBuilderStepsMenu {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .rcs_shape_wizard_content ul li.rcs_setting_details {
    line-height: 0.7;
  }

  .StepsNum {
    padding-left: 22px;
    line-height: 65px;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    top: 10px;
    right: 2px;
    width: 35px;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
  .ringBuilderStepsMenu {
    width: 100%;
  }

  .rcs_shpae_padding.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .rcs_shape_wizard .col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
    display: none !important;
  }

  /* .rcs_shape_wizard_content ul li.rcs_setting_price p {
    font-size: 10px;
  }

  .rcs_shape_wizard_content ul li.rcs_setting_price {
    top: 45px;
    right: 0;
    z-index: 1;
  } */
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    position: absolute;
    top: 12px;
    right: 0px;
    width: 40px;
    border-radius: 50%;
  }
}

.horizontalFilter .rcs_price_range_input1 input {
  height: auto !important;
  width: auto !important;
}

.rcs_cut_slider_diamond {
  position: relative;
  top: 26px;
}

@media (min-width: 991px) and (max-width: 1279px) {
  .horizontalFilter .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .HFilter_clarity_slider {
    position: relative;
    top: 30px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .horizontalFilter .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .HFilter_clarity_slider {
    position: relative;
    top: 30px;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  /* .horizontalFilter .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  } */
}


.rcs_clarity_slider_Horizontal_fancy {
  position: relative;
  top: 28px
}