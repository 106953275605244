.BannerTop {
    background: var(--inner-primary);
    text-align: center;
    padding: 50px;
    color: #FFFFFF;
    font-family: var(--inner_font_1);
}

.BannerTop .subTitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    /* margin: 0; */
    padding: 0;
    text-transform: uppercase;
}

.BannerTop .Title {
    font-size: 50px;
    font-weight: 550;
    line-height: 46px;
    /* margin: 0; */
    padding: 10px 0 15px;
    text-transform: uppercase;
}

.BannerTop .BreadcrumbS li a {
    color: #fff;
}

.BannerTop .BreadcrumbS li {
    display: inline;
    padding: 0 5px;
    font-family: var(--inner_font);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-transform: capitalize;
}

.BannerTop p {
    font-size: 18px;
    font-family: var(--inner_font);
}

.MainBoxRingDiamondShop {
    display: flex;
    /* padding: 0 85px; */
}

.MainBoxRingDiamondShop .LeftContainer {
    width: 31%;
    background: #f9f9f9;
    margin-top: 30px;
    margin-right: 30px;
    padding: 30px 0;
    /* position: sticky; */
    /* top: 80px; */
    height: max-content;
}

.MainBoxRingDiamondShop .RightContainer {
    width: 69%;
}

.MainBoxRingDiamondShop .LeftContainer .titlebox {
    display: flex;
    justify-content: space-between;
}

.MainBoxRingDiamondShop .LeftContainer .Title {
    font-family: var(--inner_font);
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    color: var(--inner-primary);
    margin: 0;
}

.MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter {
    /* border: 0.5px solid #FFFFFF; */
    font-family: var(--inner_font);
    font-size: 14px;
    font-weight: 500;
    background: #d3d3d3;
    color: var(--inner-primary);
    padding: 0 10px;
    height: 32px
}
.MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter svg {
    width: 17px;
    height: 17px;
}
.showcase_diamonds_page_top_btn {
    /* padding: 30px 0; */
}

.showcase_diamonds_page_top_btn .showcase_diamonds_page_top_btn_title {
    font-family: var(--inner_font);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
}

.showcase_diamonds_page_top_btn ul li {
    width: 48%;
}

.showcase_diamonds_page_top_btn ul li button {
    font-family: var(--inner_font);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: var(--inner-primary);
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #DFE4E8;
    border-radius: 0;
    height: 50px;
    outline: none;
    white-space: nowrap;
}

.showcase_diamonds_page_top_btn ul li button svg {
    margin-right: 8px;
}

.showcase_diamonds_page_top_btn ul li button.active {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 20px 0px #0000001A !important;
    border-width: 0px;
    font-weight: 500;
}

.FilterBox {
    
}

.FilterBox .FilterBox_inner {
    /* margin-bottom: 30px; */
    background: #f9f9f9;
}

.FilterBox .FilterBox_inner .FilterBoxTitle {
    font-family: var(--inner_font);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
}

.FilterBox .FilterBox_inner .FilterBoxIcons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
}

.FilterBox .FilterBox_inner .FilterBoxIcons li {
    /* margin-right: 10px; */
    /* margin-bottom: 10px; */
    border: 0;
    width: auto;
}

.FilterBox .FilterBox_inner .FilterBoxIcons li img {
    border: 1px solid #DFE4E8;
    /* width: 80px; */
    /* height: 80px; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    margin-bottom: 6px;
    position: relative;
}

.FilterBox .FilterBox_inner .FilterBoxIcons li img {
    /* width: 80%; */
    /* width: auto; */
}

.FilterBox .FilterBox_inner .FilterBoxIcons li:nth-child(5),
.FilterBox .FilterBox_inner .FilterBoxIcons li:nth-child(10) {
    /* margin-right: 0; */
}

.FilterBox .FilterBox_inner .FilterBoxIcons li.active {
    border: 0 !important;
    box-shadow: none;
}

.FilterBox .FilterBox_inner .FilterBoxIcons li.active img {
    box-shadow: 0px 4px 20px 0px #0000001A;
    background: #FFFFFF;
    border-width: 0 !important;
    border-radius: 0;
}

.FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
    display: block;
    /* position: absolute; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: -25px; */
    font-family: var(--inner_font);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #000000;
    /* white-space: nowrap; */
    text-align: center;
}

.engRingMetalType .shapeTitle {
    white-space: wrap !important;
    bottom: -35px !important;
}

.FilterBox .FilterBox_inner .FilterBoxIcons li.active .shapeTitle {
    font-weight: 500;
}

.OtherShape {
    border: 1px solid #DFE4E8;
    font-family: var(--inner_font);
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    display: block;
    width: 100%;
    margin-top: 10px;
    box-shadow: none !important;
    outline: none !important;
    height: 40px;
}

.OtherShapeActive {
    background: #fff;
    box-shadow: 0px 4px 20px 0px #0000001A;
    border: 0;
}

.OtherShape svg {
    margin-left: 6px;
}

.AdvaFilter {
    border-bottom: 0 !important;
    background-color: #f5f8f9 !important;
}

.AdvaFilter p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdvaFilter p span {
    display: block;
    font-family: var(--inner_font);
    font-size: 16px;
    font-weight: 500;
    color: var(--inner-primary);
    white-space: nowrap;
    padding-right: 10px;
}

.AdvaFilter p span::before {
    content: '';
    display: inline-block;
    background: linear-gradient(90deg, rgba(20, 52, 74, 0) 0%, var(--inner-primary) 100%);
    width: 31%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 22px;
}

.AdvaFilter p span::after {
    content: '';
    display: inline-block;
    background: linear-gradient(-90deg, rgba(20, 52, 74, 0) 0%, var(--inner-primary) 100%);
    width: 31%;
    height: 1px;
    position: absolute;
    right: 0;
    top: 22px;
}

.AdvaFilter>div>svg {
    color: var(--inner-primary);
    width: 24px;
    height: 24px;
}

.TopviewProductsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

.TopviewProductsBox .TopviewProductsBoxLeft {}

.TopviewProductsBox .TopviewProductsBoxLeft span {
    font-family: var(--inner_font);
    font-size: 14px;
    font-weight: 500;
    color: var(--inner-primary);
    /* background: #dce1e4; */
    /* height: 40px; */
    /* line-height: 40px; */
    text-align: center;
    display: inline-block;
    /* padding: 0 15px; */
}

.TopviewProductsBox .TopviewProductsBoxRight {
    display: flex;
    justify-content: end;
    align-items: center;
}

.TopviewProductsBoxtitle {
    font-family: var(--inner_font);
    font-size: 16px;
    font-weight: 500;
    color: var(--inner-primary);
    margin-right: 20px;
}

.TopviewProductsviewIcon {
    /* margin-right: 20px; */
}

.TopviewProductsviewIcon svg {
    color: #aaadaf96;
    fill: #aaadaf96;
}

.TopviewProductsviewIcon svg path {
    color: #aaadaf96;
    fill: #aaadaf96;
}

.TopviewProductsviewIcon span {
    margin: 0 10px;
}

.TopviewProductsshortBy {
    display: flex;
    align-items: center;
}

.TopviewProductsshortBy label {
    margin: 0;
}

.TopviewProductsshortBy select {
    border: 0 !important;
    outline: none !important;
    font-family: var(--inner_font);
    font-size: 15px;
    font-weight: 400;
    line-height: 0;
    margin-left: 10px;
    color: var(--inner-primary);
    position: relative;
    /* top: -6px; */
}

.TopviewProductsshortBy .TopviewProductsshortByTitle {
    font-family: var(--inner_font);
    font-size: 16px;
    font-weight: 500;
    line-height: 0;
    color: var(--inner-primary);
    white-space: nowrap;
}

.TopviewProductsshortBy .TopviewProductsshortByTitle i {
    font-family: var(--inner_font);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    display: block;
    position: relative;
    top: 8px;
    color: var(--inner-primary);
}

.TopviewProductsVline {
    margin: 0 30px;
}

.HoverBoxIcons {
    position: absolute;
    top: -8px;
    display: flex;
    justify-content: end;
    width: 100%;
    right: 3px;
}

@keyframes slide-in1 {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.HoverBox {
    position: relative;
}

.HoverBoxWishlist {
    position: relative;
    z-index: 1;
}

.HoverBoxWishlist svg {
    width: 50px;
    height: 50px;
    margin: 2px -10px;
}

.HoverBoxWishlist .HoverBoxWishlist2 {
    display: none;
}

.HoverBoxWishlist:hover .HoverBoxWishlist2 {
    display: block;
}

.HoverBoxWishlist:hover .HoverBoxWishlist1 {
    display: none;
}




@media (min-width:1280px) and (max-width:1439px) {
    .MainBoxRingDiamondShop {
        display: flex;
        padding: 0 0px;
    }

    .MainBoxRingDiamondShop .LeftContainer .Title {
        font-size: 18px;
        margin: 0;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons li {
        /* width: 50px; */
        /* height: 50px; */
        /* margin-right: 6px; */
        /* margin-bottom: 6px; */
    }

    .AdvaFilter p span::before,
    .AdvaFilter p span::after {
        width: 25%;
    }

    .showcase_diamonds_page_top_btn ul li button {
        font-size: 14px;
    }

    .HoverBoxWishlist svg {
        width: 43px;
        height: 43px;
        margin: 2px -7px;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
        font-size: 9px;
        /* height: 20px; */
    }

    .engRingMetalType .shapeTitle {
        white-space: wrap !important;
        bottom: -25px !important;
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .showcase_diamonds_page_top_btn .showcase_diamonds_page_top_btn_title, .FilterBox .FilterBox_inner .FilterBoxTitle {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
        /* height: 20px; */
    }

    .MainBoxRingDiamondShop {
        display: flex;
        padding: 0 0px;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons li {
        /* width: 60px; */
        /* height: 60px; */
    }

    .AdvaFilter p span::before {
        width: 29%;
    }

    .AdvaFilter p span::after {
        width: 29%;
    }
}


@media (min-width:992px) and (max-width:1279px) {

    .BannerTop {
        padding: 20px;
    }

    .BannerTop .Title {
        font-size: 30px;
        line-height: normal;
    }
    .BannerTop .subTitle {
        margin-top: 5px !important;
        font-size: 20px;
        line-height: normal;
    }

    .MainBoxRingDiamondShop {
        display: flex;
        padding: 0 0px;
    }

    .Col_box {
        /* max-width: 50%; */
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .TopviewProductsshortBy .TopviewProductsshortByTitle,
    .TopviewProductsBoxtitle {
        font-size: 15px;
    }

    .HoverBoxWishlist svg {
        width: 45px;
        height: 45px;
        /* margin: -8px -15px; */
    }

    .showcase_diamonds_page_top_btn ul li button {
        font-size: 12px;
    }

    .showcase_diamonds_page_top_btn ul li button svg {
        width: 18px;
    }

    .MainBoxRingDiamondShop .LeftContainer {
        width: 37%;
        margin-top: 20px;
        margin-right: 20px;
        padding: 10px;
    }

    .MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter {
        font-size: 13px;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons li {
        /* width: 42px; */
        /* height: 42px; */
        /* margin-right: 6px; */
        /* margin-bottom: 15px; */
    }

    .AdvaFilter p span::before,
    .AdvaFilter p span::after {
        width: 20%;
    }

    .MainBoxRingDiamondShop .LeftContainer .Title {
        font-size: 20px;
    }


    .showcase_diamonds_page_top_btn .showcase_diamonds_page_top_btn_title,
    .FilterBox .FilterBox_inner .FilterBoxTitle {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
        font-size: 8px;
        line-height: 10px;
        /* bottom: -28px !important; */
        /* height: 20px; */
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons li img {}

}


@media (min-width:769px) and (max-width:991px) {}

@media (max-width:991px) {
    
    .FilterBox .FilterBox_inner .FilterBoxIcons li {
        /* margin-right: 7px; */
        /* margin-bottom: 6px; */

    }
    .FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
        font-size: 10px;
        line-height: 13px;
        bottom: -28px;
        /* height: 20px; */
    }

    .MainBoxRingDiamondShop .LeftContainer {
        position: static;
        height: max-content;
    }

    .MainBoxRingDiamondShop {
        padding: 0 0px;
        flex-wrap: wrap;
    }

    .TopviewProductsshortBy .TopviewProductsshortByTitle,
    .TopviewProductsBoxtitle {
        font-size: 12px;
    }

    .TopviewProductsshortBy .TopviewProductsshortByTitle i {
        font-size: 12px;
    }

    .TopviewProductsshortBy select {
        font-size: 13px;
    }

    .HoverBoxWishlist svg {
        width: 45px;
        height: 45px;
        margin: -8px -15px;
    }

    .showcase_diamonds_page_top_btn ul li button {
        font-size: 12px;
    }

    .MainBoxRingDiamondShop .LeftContainer {
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        padding: 15px;
        margin-bottom: 10px;
    }

    .MainBoxRingDiamondShop .RightContainer {
        width: 100%;
    }

    .FilterBox .FilterBox_inner .FilterBoxIcons li img {
        width: 58px;
        height: 58px;
        padding: 5px;
        object-fit: contain;
    }


    .AdvaFilter p span::before,
    .AdvaFilter p span::after {
        width: 20%;
    }


    .TopviewProductsBox {
        flex-wrap: wrap;
        padding: 15px 0 10px;
    }

    .TopviewProductsBox .TopviewProductsBoxLeft {
        /* justify-content: start; */
        margin-bottom: 10px;
    }

    .TopviewProductsviewIcon svg {
        min-width: 15px;
        width: 15px;
        height: 15px;
    }

    .TopviewProductsVline {
        margin: 0 15px;
        width: auto;
    }

    .BannerTop {
        padding: 20px 0;
    }

    .BannerTop .subTitle {
        font-size: 22px;
    }

    .BannerTop .Title {
        font-size: 35px;
        padding: 0;
    }

    .showcase_diamonds_page_top_btn {
        padding: 10px 0;
    }

    .showcase_diamonds_page_top_btn .showcase_diamonds_page_top_btn_title,
    .FilterBox .FilterBox_inner .FilterBoxTitle {
        font-size: 16px;
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 5px;
    }

    .MainBoxRingDiamondShop .LeftContainer .Title {
        font-size: 20px;
    }

    .MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter {
        font-size: 14px;
    }

    .MainBoxRingDiamondShop .LeftContainer .filterPadding {
        padding-bottom: 25px;
    }
    

}

@media (min-width:540px) and (max-width:991px) {

    .FilterBox .FilterBox_inner .FilterBoxIcons li:nth-child(5),
    .FilterBox .FilterBox_inner .FilterBoxIcons li:nth-child(10) {
        margin-right: 10px;
    }
}

@media(max-width:768px) {
    .BannerTop {
        padding: 5px 10px 5px;
        background-color: #fff;
        text-align: left;
    }

    .BannerTop .BreadcrumbS li a,
    .BannerTop .BreadcrumbS li {
        color: var(--inner-primary);
        font-size: 11px;
    }

    .BannerTop .BreadcrumbS li {
        padding: 0 3px;

    }

    .BannerTop .BreadcrumbS {
        line-height: 15px;

    }

    .BannerTop .BreadcrumbS li:last-child {
        padding-right: 0;
    }

    .BannerTop .subTitle,
    .BannerTop p,
    .BannerTop .Title {
        display: none;
    }
}

@media(min-width: 1279px) {

    .MainBoxRingDiamondShop .LeftContainer,
    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        /* position: sticky; */
        /* top: 100px; */
        /* overflow: auto; */
        /* max-height: calc( 100vh - 150px + 325px + 65px); */
    }

    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        padding: 15px 30px;
        /* overflow: auto; */
        /* max-height: calc( 100vh - 150px + 325px + 65px); */
    }

    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner.filterPadding {
        padding-bottom: 30px;
    }

    .MainBoxRingDiamondShop .LeftContainer .titlebox {
        padding: 0 30px;
    }
}

@media(min-width: 1500px) {

    .MainBoxRingDiamondShop .LeftContainer,
    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        /* position: sticky; */
        /* top: 130px; */
    }

}

@media (min-width:1440px) and (max-width:1900px) {

    .MainBoxRingDiamondShop .LeftContainer,
    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        /* position: sticky; */
        /* top: 122px; */
    }

    .engRingMetalType .shapeTitle {
        bottom: -25px !important;
    }
}

@media(min-width:991px) and (max-width:1277px) {
    .MainBoxRingDiamondShop .LeftContainer {
        /* padding: 15px; */
    }

    .FilterBox .FilterBox_inner {
        padding: 5px 0 0;
        margin: 0;
     }

    .filterPadding {
        padding-bottom: 25px !important;
    }
}

@media(min-width:769px) and (max-width: 990px) {
    .TopviewProductsBox {
        flex-wrap: nowrap;
    }

    .FilterBox .FilterBox_inner {
        margin-bottom: 20px;
        /* background: #F5F8F9; */
    }
    .FilterBox .FilterBox_inner .FilterBoxIcons {
        grid-template-columns: repeat(10,1fr);
    }

}

@media(min-width:992px) and (max-width: 1199px) {
    .MainBoxRingDiamondShop .RightContainer {
        width: 100%;
    }
}

@media(min-width:767px) and (max-width: 769px) {
    .FilterBox .FilterBox_inner {
        /* margin-bottom: 20px; */
    }
}

@media(min-width:1900px) {
    .ProductListPage .LeftContainer {
        width: 20%;
    }

    .ProductListPage .RightContainer {
        width: 80%;
    }
}

@media (min-width:1280px) and (max-width:1439px) {
    .showcase_diamonds_page_top_btn ul li button svg {
        width: 25px;
        height: 25px;
    }

    .MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter {
        font-size: 14px;
    }

    .MainBoxRingDiamondShop .LeftContainer .ShowcaseClearFilter svg {
        font-size: 16px;
    }

    .MainBoxRingDiamondShop .LeftContainer .titlebox {
        padding: 0 20px;
    }

    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        padding: 8px 20px;
    }

    .ProductListPage .LeftContainer {
        width: 25%;
    }

    .ProductListPage .LeftContainer>div {
        padding: 0 20px !important;
    }

    .ProductListPage .RightContainer {
        width: 75%;
    }

    .TopviewProductsBox {
        padding: 20px 0;
    }

    .MainBoxRingDiamondShop .LeftContainer {
        margin-top: 20px;
        margin-right: 20px;
        padding: 20px 0;
    }

    .showcase_diamonds_page_top_btn .showcase_diamonds_page_top_btn_title,
    .FilterBox .FilterBox_inner .FilterBoxTitle {
        font-size: 16px;
        margin-bottom: 8px;
    }
}

@media (min-width:1200px) and (max-width:1279px) {
    .ProductListPage .LeftContainer {
        width: 28%;
    }

    .ProductListPage .LeftContainer>div {
        padding: 0 20px !important;
    }

    .ProductListPage .RightContainer {
        width: 72%;
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .ProductListPage .LeftContainer {
        width: 25%;
    }

    .ProductListPage .RightContainer {
        width: 75%;
    }
    .MainBoxRingDiamondShop .LeftContainer .FilterBox_inner,
    .showcase_diamonds_page_top_btn {
        padding: 10px 30px;
        /* overflow: auto; */
        /* max-height: calc( 100vh - 150px + 325px + 65px); */
    }
    .FilterBox .FilterBox_inner .FilterBoxIcons .shapeTitle {
        font-size: 10px;
    }
}
@media(max-width:768px){
    .HoverBoxWishlist svg {
        margin: 2px -9px;
    }
    .FilterBox .FilterBox_inner .FilterBoxIcons li {
        max-width: 58px;
    }
    .HoverBoxWishlist .HoverBoxWishlist2,.HoverBoxWishlist:hover .HoverBoxWishlist2 {
        display: none;
    }
    .HoverBoxWishlist .HoverBoxWishlist1,.HoverBoxWishlist:hover .HoverBoxWishlist1 {
        display: block;
    }
    .OtherShape {

    }
}

@media (min-width:991px) and (max-width:1279px) {
.OtherShape {
    font-size: 10px;
    height: 30px;
}
}
@media (min-width:768px) and (max-width:992px) {
    .FilterBox {
        background: #f9f9f9;
    }
    .FilterBox .FilterBox_inner .FilterBoxIcons {
        justify-content: space-between;
        grid-template-columns: repeat(10, 1fr);
    }
    
    
}

@media(max-width: 350px){
    .FilterBox .FilterBox_inner .FilterBoxIcons {
        grid-template-columns: repeat(4, 1fr);
    }
}