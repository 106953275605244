.Zoom_effact_image_tileView figure:before {
  background: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.03;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.Zoom_effact_image_tileView .iiz__btn.iiz__hint {
  opacity: 0;
}

.productViewBtn,
.productViewBtn:hover {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  padding: 4px 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.Zoom_effact_image_tileView figure.iiz {
  width: 100%;
  margin: auto;
  display: table;
  margin-bottom: 8px;
}
.Zoom_effact_image_tileView figure.iiz .iiz__img {
  margin: auto;
  object-fit: contain;
}

@media (min-width: 1200px) {
  .Zoom_effact_image_tileView figure.iiz .iiz__img {
    min-height: 300px;
  }
}